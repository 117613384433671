import { Children, isValidElement } from 'react';
import type { ComponentType, ReactElement, ReactNode } from 'react';

import type { IGridProps } from './grid.types';
import { classNames } from 'utils/string';

const getValidChildren = (children: ReactNode) => {
  return Children.toArray(children).filter(child =>
    isValidElement(child),
  ) as ReactElement[];
};

const getFlexDirection = (orientation: 'x' | 'y', reverse: boolean) => {
  if (orientation === 'x') {
    if (!reverse) {
      return 'row';
    }

    return 'row-reverse';
  }

  if (!reverse) {
    return 'column';
  }

  return 'column-reverse';
};

const Grid: ComponentType<IGridProps> = ({
  children,
  orientation,
  wrap,
  reverse = false,
  ...restProps
}) => {
  const validChildren = getValidChildren(children);

  const flexDirection = getFlexDirection(orientation, reverse);

  return (
    <div
      style={{ flexDirection, display: 'flex' }}
      className={classNames({ flexWrap: wrap }, restProps.className)}
      {...restProps}>
      {validChildren}
    </div>
  );
};

export default Grid;
