import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import Avatar from 'atoms/avatar'
import { IReviewerFeedbackContentProps } from './reviewerFeedback.types'

const ReviewerFeedbackContent: React.FC<IReviewerFeedbackContentProps> = ({ reviewerName, reviewerImage, reviewerFeedbackText, timeString, width=650 }) => {

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const iconStyle = 'w-4 h-4 text-fontDark'

    const reviewerFeedbackHeader = (
        <div className='flex justify-between w-full items-center'>
            <p className='text-sm font-medium'>Reviewer feedback</p>
            {isOpen? <ChevronUpIcon className={iconStyle} /> : <ChevronDownIcon className={iconStyle} />}
        </div>
    )

    const classNameString = `w-[${width}px] border-[1px] border-borderSecondary p-3 rounded-md`

  return (
    <Collapsible className={classNameString} openedClassName={classNameString} trigger={reviewerFeedbackHeader} transitionTime={200} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
        <div className='py-2'>

            {/* Header */}
            <div className='flex items-center pb-1'>
                <Avatar userName={reviewerName} imageSrc={reviewerImage} width='w-5' height='h-5' initialsSize='text-xs'/>
                <p className='text-sm px-2 text-gray-900'>{reviewerName}</p>
                <p className='text-[12px] leading-4 text-[#7A7A7A]'>{timeString}</p>
            </div>

            {/* Feedback */}
            {reviewerFeedbackText? 
                <div className='text-[13px] leading-4 text-[#7A7A7A] pl-7 pb-5'>
                    {reviewerFeedbackText}
                </div>
                :
                <div className='w-full flex justify-center pb-5'>
                    <h1 className='text-fontGrey1 text-[32px]'>No Feedback</h1>
                </div>
            }

        </div>
    </Collapsible>
  )
}

export default ReviewerFeedbackContent