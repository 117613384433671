import { ComponentType } from "react";
import { IPillProps, IPillVariant } from "./pill.types";

const getVariantClasses = (variant: IPillVariant): string => {
  return `inline-flex items-center rounded-full bg-${variant}-50 px-2 py-1 text-xs font-medium text-${variant}-700 ring-1 ring-inset ring-${variant}-600/10`;
};

const Pill: ComponentType<IPillProps> = ({ variant, children, ...props }) => {
  const variantClasses = getVariantClasses(variant);

  return (
    <span className={variantClasses} {...props}>
      {children}
    </span>
  );
};

export default Pill;
