import { useToast } from "@/components/ui/toast/use-toast";
import { Input } from "atoms";
import { BtnText } from "constant/enums/btn-txt.enum";
import { RouterUrls } from "constant/enums/routes.enum";
import { WebApiEndPointUrls } from "constant/enums/webapi-endpoint.enum";
import {
  ALREDY_HAVE_ACCOUNT,
  APP_TAG_LINE,
  DONT_HAVE_ACCOUNT,
  ENTER_EMAIL_ADDRESS,
  GOOGLE,
  INVALID_EMAIL,
  LOGIN_HELPER,
  LOGIN_MODE,
  PLEASE_ENTER_EMAIL,
  SIGNUP_MODE,
  VERIFICATION_EMAIL_FAILIURE,
  VERIFICATION_EMAIL_SUCCESS
} from "constant/texts/login";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router";
import { axiosInstance } from "utils/axios/axios-instance.utils";
import { validateEmail } from "utils/common/common-utils";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "utils/string";
import { Link } from "react-router-dom";

const Auth = () => {
  const routeInfo = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [emailInput, setEmailInput] = useState(routeInfo?.state?.email ? routeInfo?.state?.email : "");
  const { toast } = useToast();

  const isLoginRoute = routeInfo?.pathname === RouterUrls.LOGIN;
  const btnText = isLoginRoute ? BtnText.LOGIN : BtnText.SIGN_UP;
  const linkText = !isLoginRoute ? BtnText.LOGIN : BtnText.SIGN_UP;
  const mode = isLoginRoute ? LOGIN_MODE : SIGNUP_MODE;

  useEffect(() => {
    cookies?.accessToken && navigate("/");
  }, []);

  const reRouteHandler = () => {
    return navigate(isLoginRoute ? RouterUrls.SIGN_UP : RouterUrls.LOGIN);
  };

  const handleOAuthSignin = async (type: string) => {
    try {
      const res = await axiosInstance.post(`${WebApiEndPointUrls.OAUTH_AUTH}/${type}`);

      if (res?.data?.data?.session) {
        window.location.href = res?.data?.data?.session;
      }
    } catch (err: any) {
      toast({
        title: err?.response?.data?.message,
        icon: <XCircleIcon className="h-8 w-8 text-[#D86161]" />,
        className: "bg-[#ffffff]"
      });
    }
  };

  const emailAuthHandler = async () => {
    try {
      const res = await axiosInstance.post(WebApiEndPointUrls.PASSWORDLESS_AUTH, {
        auth: mode,
        email: emailInput
      });
      if (res?.data?.data) {
        navigate(`${RouterUrls.VERIFICATION}?email=${emailInput}&mode=${mode}`, {
          replace: true
        });
        toast({
          description: VERIFICATION_EMAIL_SUCCESS,
          icon: <CheckCircleIcon className="h-6 w-6 text-[#59B07A]" />,
          className: "bg-[#ffffff]"
        });
      }
    } catch (err: any) {
      toast({
        description: err?.response?.data?.message || VERIFICATION_EMAIL_FAILIURE,
        icon: <XCircleIcon className="h-8 w-8 text-[#D86161]" />,
        className: "bg-[#ffffff]"
      });
    }
  };

  const submitClickHandler = () => {
    if (validateEmail(emailInput)) {
      emailAuthHandler();
    } else {
      toast({
        description: emailInput ? INVALID_EMAIL : PLEASE_ENTER_EMAIL,
        icon: <XCircleIcon className="h-8 w-8 text-[#D86161]" />,
        className: "bg-[#ffffff]"
      });
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="md:border-solid md:border md:border-grey w-full md:w-[35rem] m-*:auto rounded-3xl">
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 items-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-14 w-auto" src="/logo.png" alt="Feedback Hero" />
            <h2 className="mt-4 text-center text-[#182021] font-normal text-base">{APP_TAG_LINE}</h2>
          </div>

          <div className="flex grow-0 mt-12 flex-col md:flex-row w-full md:w-auto">
            <button
              className="mr-4 inline-flex items-center justify-center rounded-[8px] h-12 w-full md:w-[236px] px-2 py-3 bg-[#EDEDEE] hover:bg-grayHover font-medium text-[14px] text-[#121212] mb-3 md:mb-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark"
              onClick={() => handleOAuthSignin("GoogleOAuth")}
            >
              <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                <path
                  d="M24.5005 12.6969C24.5005 11.8811 24.433 11.061 24.289 10.2585H12.7422V14.8794H19.3545C19.0801 16.3698 18.1985 17.6882 16.9075 18.5258V21.5241H20.8524C23.169 19.4341 24.5005 16.3477 24.5005 12.6969Z"
                  fill="#4285F4"
                />
                <path
                  d="M12.7423 24.4227C16.044 24.4227 18.8284 23.3601 20.857 21.5258L16.9122 18.5275C15.8146 19.2595 14.3977 19.674 12.7468 19.674C9.55313 19.674 6.84522 17.5619 5.87361 14.7224H1.80273V17.8132C3.88091 21.8655 8.11371 24.4227 12.7423 24.4227Z"
                  fill="#34A853"
                />
                <path
                  d="M5.86859 14.7205C5.3558 13.2301 5.3558 11.6164 5.86859 10.126V7.03516H1.80223C0.0659239 10.4259 0.0659239 14.4206 1.80223 17.8113L5.86859 14.7205Z"
                  fill="#FBBC04"
                />
                <path
                  d="M12.7423 5.17217C14.4876 5.14572 16.1745 5.78946 17.4385 6.97115L20.9335 3.54515C18.7205 1.50809 15.7831 0.388142 12.7423 0.423416C8.11369 0.423416 3.88091 2.98078 1.80273 7.03728L5.8691 10.1282C6.83621 7.28419 9.54862 5.17217 12.7423 5.17217Z"
                  fill="#EA4335"
                />
              </svg>
              {`${btnText} with ${GOOGLE}`}
            </button>
            <button
              className="inline-flex items-center justify-center rounded-[8px] h-12 w-full md:w-[236px] px-2 py-3 bg-[#EDEDEE] hover:bg-grayHover font-medium text-[14px] text-[#121212] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark"
              onClick={() => handleOAuthSignin("MicrosoftOAuth")}
            >
              <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2">
                <g clip-path="url(#clip0_12234_11021)">
                  <path d="M11.9062 11.8288H0.5V0.422607H11.9062V11.8288Z" fill="#F1511B" />
                  <path d="M24.4998 11.8288H13.0938V0.422607H24.4998V11.8288Z" fill="#80CC28" />
                  <path d="M11.9059 24.4228H0.5V13.0166H11.9059V24.4228Z" fill="#00ADEF" />
                  <path d="M24.4998 24.4228H13.0938V13.0166H24.4998V24.4228Z" fill="#FBBC09" />
                </g>
                <defs>
                  <clipPath id="clip0_12234_11021">
                    <rect width="24" height="24" fill="white" transform="translate(0.5 0.422607)" />
                  </clipPath>
                </defs>
              </svg>
              {`${btnText} with Microsoft`}
            </button>
          </div>

          <div className="relative flex items-center w-full mt-8">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="flex-shrink mx-2 text-gray-300 text-xs">or</span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>

          <div className="mt-8 w-full">
            <div className="space-y-6">
              <div>
                <Input
                  label={"Email"}
                  error={""}
                  placeholder={ENTER_EMAIL_ADDRESS}
                  value={emailInput}
                  onChange={(event) => setEmailInput(event?.target?.value)}
                />
                <p className="text-xs font-normal mt-2">{LOGIN_HELPER}</p>
              </div>

              <div className="text-sm font-normal mt-4">
                By signing up you are agreeing to the
                <Link to={"/terms-conditions"} className="font-medium text-[#028735]">
                  {" "}
                  terms{" "}
                </Link>
                and{" "}
                <Link to={"/privacy-policy"} className="font-medium text-[#028735]">
                  {" "}
                  privacy policy.
                </Link>
              </div>

              <div className="mt-6">
                <button
                  className="flex w-full items-center justify-center rounded-[5px] font-medium  rounded-md bg-primary px-2.5 py-1.5 text-sm font-semibold text-fontDark shadow-sm hover:bg-primaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark"
                  onClick={submitClickHandler}
                >
                  {btnText}
                </button>
                <p className={classNames("text-xs font-normal mt-2", isLoginRoute ? "hidden" : "")}>
                  By signing up, you are agreeing to the
                  <span className="text-xs mx-1 cursor-pointer underline" onClick={() => navigate(RouterUrls.TERMS_CONDITIONS)}>
                    terms and conditions
                  </span>
                  and{" "}
                  <span className="text-xs cursor-pointer underline" onClick={() => navigate(RouterUrls.PRIVACY_POLICY)}>
                    privacy policy
                  </span>
                </p>
              </div>
            </div>

            <p className="mt-6 text-center text-sm font-normal">
              {isLoginRoute ? DONT_HAVE_ACCOUNT : ALREDY_HAVE_ACCOUNT}
              <span onClick={reRouteHandler} className="text-sm font-medium text-[#028735] ml-1 cursor-pointer">
                {linkText}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
