import { Button } from "atoms";
import Checkbox from "atoms/checkbox";
import HeadingWithDescription from "components/headingWithDescription";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { BtnText } from "constant/enums/btn-txt.enum";
import { useEffect, useState } from "react";
import AlertModal from "components/alertModal";
import Modal from "components/modal";
import Table from "components/table";
import { WebApiEndPointUrls } from "constant/enums/webapi-endpoint.enum";
import { axiosInstance } from "utils/axios/axios-instance.utils";
import { Permission } from "constant/enums/permission.enum";
import { useToast } from "@/components/ui/toast/use-toast";

interface IPermission {
  role: string;
  displayName: string;
  permissions: string[];
}

const columns = (checkboxHandler) => [
  {
    name: "USER ROLE",
    selector: (row) => row.displayName,
    grow: 2
    // width: "300px"
  },
  {
    name: "VIEW",
    selector: (row) => (
      <Checkbox
        id={Permission.VIEW}
        name={Permission.VIEW}
        disabled
        isChecked={row.permissions.includes(Permission.VIEW) ? true : false}
        value={Permission.VIEW}
        onClick={() => checkboxHandler(row.role, Permission.VIEW)}
      />
    )
  },
  {
    name: "EDIT",
    selector: (row) => (
      <Checkbox
        id={Permission.EDIT_SEND_FEEDBACK}
        name={Permission.EDIT_SEND_FEEDBACK}
        disabled={row.role === "OWNER"}
        isChecked={row.permissions.includes(Permission.EDIT_SEND_FEEDBACK) ? true : false}
        value={Permission.EDIT_SEND_FEEDBACK}
        onClick={() => checkboxHandler(row.role, Permission.EDIT_SEND_FEEDBACK)}
      />
    )
  },
  {
    name: "INVITE",
    selector: (row) => (
      <Checkbox
        id={Permission.INVITE_MANAGE_TEAM}
        name={Permission.INVITE_MANAGE_TEAM}
        disabled={row.role === "OWNER"}
        isChecked={row.permissions.includes(Permission.INVITE_MANAGE_TEAM) ? true : false}
        value={Permission.INVITE_MANAGE_TEAM}
        onClick={() => checkboxHandler(row.role, Permission.INVITE_MANAGE_TEAM)}
      />
    )
  }
];

const ManageUserPermissions = () => {
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [initialPermissions, setInitialPermissions] = useState<IPermission[]>([]);

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = async () => {
    try {
      const res = await axiosInstance.get(WebApiEndPointUrls.PERMISSIONS);
      if (res.data?.data) {
        setInitialPermissions(res.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const updatedPermissions = initialPermissions.map((_) => ({ ..._, permissions: [..._.permissions] }));
    setPermissions(updatedPermissions);
  }, [initialPermissions]);

  useEffect(() => {
    setPermissionsUpdated(JSON.stringify(initialPermissions) !== JSON.stringify(permissions));
  }, [permissions, initialPermissions]);

  const [isPermissionsUpdated, setPermissionsUpdated] = useState(false);

  const permissionHandler = (role: string, permission: string) => {
    const updatedPermissions = [...permissions];
    const roleIndex = updatedPermissions.findIndex((per) => per.role === role);
    if (roleIndex > -1) {
      const updatedObjRole = { ...updatedPermissions[roleIndex] };
      if (updatedObjRole.permissions) {
        const updatedPermission = [...updatedObjRole.permissions];
        const permissionIndex = updatedPermission.findIndex((_) => _ === permission);
        if (permissionIndex > -1) {
          // permission already exist, we need to remove
          updatedPermission.splice(permissionIndex, 1);
        } else {
          // permission is not present, we need to add
          updatedPermission.push(permission);
        }
        updatedObjRole["permissions"] = updatedPermission;
        updatedPermissions[roleIndex] = updatedObjRole;
      }
      setPermissions(updatedPermissions);
    }
  };

  const resetPermissions = () => {
    const updatedPermissions = initialPermissions.map((_) => ({ ..._, permissions: [..._.permissions] }));
    setPermissions(updatedPermissions);
    setShowDiscardPopup(false);
  };

  const [showDiscardPopup, setShowDiscardPopup] = useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { toast } = useToast();

  const saveHandler = async () => {
    try {
      const res = await axiosInstance.patch(
        WebApiEndPointUrls.PERMISSIONS,
        permissions.map((_) => ({ role: _.role, permissions: _.permissions })),
        {
          withCredentials: true
        }
      );
      if (res?.status === 200) {
        const updatedPermissions = permissions.map((_) => ({ ..._, permissions: [..._.permissions] }));
        setInitialPermissions(updatedPermissions);
        toast({
          description: "Permissions updated successfully.",
          variant: "success"
        });
      } else {
        throw new Error("Unable to update permissions");
      }
    } catch (error) {
      toast({
        description: "Something went wrong.",
        variant: "error"
      });
    }
    setShowConfirmDialog(false);
  };

  return (
    permissions.length !== 0 && (
      <>
        <section className="flex flex-col mt-12">
          <HeadingWithDescription
            heading="Manage user permissions"
            description="This is where you can manage permissions for each user role. You cannot create roles."
          />
          <div className="flex mt-8 flex-col gap-8">
            <div className="w-[100%] sm:w-[90%] md:w-[80%] lg:w-[70%]">
              <Table
                columns={columns(permissionHandler)}
                data={permissions}
                totalRows={permissions.length}
                loading={false}
                rowsPerPage={permissions.length}
                fetchData={() => {
                  console.log("");
                }}
                setRowsPerPage={() => {
                  console.log("");
                }}
                paginationPerPage={5}
                paginationRowsPerPageOptions={[]}
                pagination={false}
              />
            </div>
            <div className="flex justify-end gap-4 w-[100%] sm:w-[94%] md:w-[84%] lg:w-[74%]">
              {isPermissionsUpdated && (
                <Button variant="tertiary" onClick={() => setShowDiscardPopup(true)}>
                  {BtnText.CANCEL}
                </Button>
              )}
              <Button onClick={() => setShowConfirmDialog(true)} disabled={!isPermissionsUpdated}>
                {BtnText.SAVE}
              </Button>
            </div>
          </div>
        </section>
        <AlertModal
          title="Discard"
          variant="error"
          open={showDiscardPopup}
          onClose={() => setShowDiscardPopup(false)}
          footerButton={
            <>
              <Button variant="danger" customClasses="sm:ml-3 w-full sm:w-auto" onClick={resetPermissions}>
                {BtnText.DISCARD}
              </Button>
              <Button variant="secondary" customClasses="mt-3 sm:mt-0 w-full sm:w-auto" onClick={() => setShowDiscardPopup(false)}>
                {BtnText.KEEP}
              </Button>
            </>
          }
        >
          <p>Are you sure you want to discard the changes you made?</p>
        </AlertModal>
        <Modal
          title="Permission Change"
          size="small"
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          children={
            <div className="flex flex-col gap-4 items-center text-center py-4">
              <div className="text-base font-medium">Are you sure you want to update the permission?</div>
              <div className="flex gap-1 bg-[#D861611A] py-4 px-12 text-left text-base text-[#D86161] font-medium rounded-[10px]">
                <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                <span>Please make sure they should have access to the information.</span>
              </div>
              <div className="text-sm/4 text-[#4D4D4D]">
                This action can be undone. You have to reset it by clicking edit permission in the Settings tab.
              </div>
            </div>
          }
          footer={
            <>
              <Button variant="danger" onClick={saveHandler}>
                {BtnText.UPDATE}
              </Button>
              <Button variant="tertiary" onClick={() => setShowConfirmDialog(false)}>
                {BtnText.CANCEL}
              </Button>
            </>
          }
        />
      </>
    )
  );
};

export default ManageUserPermissions;
