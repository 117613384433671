import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import React from 'react'

const ReviewerFeedbackLoadingSkeleton = () => {
  return (
    <div className='relative h-full'>

        {/* Header */}
        <div className="animate-pulse border-b border-borderSecondary pb-3 mt-4 mb-6">
            <div className='rounded-full w-1/2 h-8 bg-greyLoader opacity-70'></div>
        </div>

        {/* User Details  */}
        <div className="animate-pulse flex mb-8">
            <div className='rounded-full w-10 h-10 bg-greyLoader opacity-70 mr-3'></div>
            <div className='flex flex-col justify-around'>
                <div className='w-36 rounded-full h-4 bg-greyLoader opacity-70'></div>
                <div className='w-28 rounded-full h-3 bg-greyLoader opacity-70'></div>
            </div>
        </div>

        {/* Reviwer's Feedback */}
        <div className="animate-pulse border border-borderSecondary rounded-md p-3 mb-8">
            <div className='rounded-full w-40 h-6 bg-greyLoader opacity-70 mb-2'></div>
            <div className='flex items-center mb-2'>
                <div className='rounded-full w-6 h-6 bg-greyLoader opacity-70 mr-3'></div>
                <div className='rounded-full w-32 h-4 mr-3 bg-greyLoader opacity-70'></div>
                <div className='rounded-full w-32 h-4 mr-3 bg-greyLoader opacity-70'></div>
            </div>
            <div className='flex w-full'>
                <div className='w-20'></div>
                <div className='flex-1'>
                    <div className='rounded-full w-full bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-[92%] bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-[95%] bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-full bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-3/4 bg-greyLoader opacity-70 h-4 mb-1'></div>
                </div>
            </div>
        </div>

        {/* AI Feedback */}
        <div className="animate-pulse border border-borderSecondary rounded-md p-3">
            
            <div className='flex justify-between items-center mb-2'>
                <div className='rounded-full w-40 h-6 bg-greyLoader opacity-70 mb-2'></div>
                <div className='flex items-center'>
                    <div className='border border-borderSecondary rounded-md px-4 py-2 mr-4'>
                        <div className='rounded-full bg-greyLoader opacity-70 w-10 h-4'></div>
                    </div>
                    <EllipsisHorizontalIcon className='text-[#DFDFDF] w-6 h-6' />
                </div>
            </div>

            <div className='flex w-full'>
                <div className='w-20'></div>
                <div className='flex-1'>
                    <div className='rounded-full w-full bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-[92%] bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-[95%] bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-full bg-greyLoader opacity-70 h-4 mb-1'></div>
                    <div className='rounded-full w-3/4 bg-greyLoader opacity-70 h-4 mb-1'></div>
                </div>
            </div>
        </div>

        {/* Footer  */}
        <div className="animate-pulse absolute bottom-0 right-0 flex flex-row-reverse pb-3 w-full mb-3">
            <div className='border-2 border-borderSecondary bg-[#b9b9b9] py-2 px-4 w-fit ml-4 rounded-md'>
                <div className='h-5 rounded-full bg-greyLoader opacity-70 w-32'></div>
            </div>
            <div className='border-2 border-borderSecondary py-2 px-4 w-fit rounded-md'>
                <div className='h-5 rounded-full bg-greyLoader opacity-70 w-48'></div>
            </div>
        </div>

    </div>
  )
}

export default ReviewerFeedbackLoadingSkeleton;