import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast/toast"
import { useToast } from "@/components/ui/toast/use-toast"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";

const getToastIcon = (variant, icon) => {
  switch (variant) {
    case "success":
      return <CheckCircleIcon className="h-6 w-6 text-[#59B07A]" />;
    case "error":
      return <XCircleIcon className="h-8 w-8 text-[#D86161]" />;
    default:
      return icon;
  }
};

export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, icon, variant, ...props }) {
        return (
          <Toast className="bg-white" key={id} {...props}>
            <div className="flex">
            {getToastIcon(variant, icon)}
              <div className="px-4">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && (
                  <ToastDescription className="text-[16px]">{description}</ToastDescription>
                )}
              </div>
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
