export enum RouterUrls {
  HOME = "/",
  TEMPLATE_LIBRARY = "/template-library",
  SETTINGS = "/settings",
  ATOMS = "/atoms",
  MANAGE_TEAM = "/manage-team",
  LOGIN = "/login",
  SIGN_UP = "/signup",
  VERIFICATION = "/verification",
  ONBOARDING_STEP1 = "/onboarding/step-1",
  ONBOARDING_STEP2 = "/onboarding/step-2",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_CONDITIONS = "/terms-conditions"
}
