import { Button } from "atoms";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <main className="min-h-screen flex justify-center items-center bg-white px-6 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-greyMain">404 Error</p>
        <h1 className="mt-4 text-5xl font-medium tracking-tight text-greyMain sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base font-normal leading-7 text-fontGrey1">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Button onClick={() => navigate("/")}>Go back home</Button>
          <a href="#" className="text-sm font-semibold text-gray-900">
            Contact support <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  );
};

export default NotFound;
