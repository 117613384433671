import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import { RouterUrls } from "../constant/enums/routes.enum";
import {
  Home,
  TemplateLibrary,
  Settings,
  NotFound,
  Atoms,
  OnboardingStep1,
  OnboardingStep2,
  ManageTeam,
  ExpiryLinkPage,
  PrivacyPolicy,
  TermsConditions
} from "../pages";
import EmailVerification from "pages/email-verification/email-verification";
import Auth from "pages/auth/auth";
import ProtectedRoutes from "./ProtectedRoutes";
import OnboardingProtectedRoutes from "./OnboardingProtectedRoutes";

const AppRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<ProtectedRoutes />}>
          <Route element={<OnboardingProtectedRoutes />}>
            <Route path={RouterUrls.HOME} element={<Home />} />
            <Route path={RouterUrls.TEMPLATE_LIBRARY} element={<TemplateLibrary />} />
            <Route path={RouterUrls.SETTINGS} element={<Settings />} />
            <Route path={RouterUrls.ONBOARDING_STEP2} element={<OnboardingStep2 />} />
            <Route path={RouterUrls.ATOMS} element={<Atoms />} />
            <Route path={RouterUrls.MANAGE_TEAM} element={<ManageTeam />} />
          </Route>
          <Route path={RouterUrls.ONBOARDING_STEP1} element={<OnboardingStep1 />} />
        </Route>

        <Route path={RouterUrls.LOGIN} element={<Auth />} />
        <Route path={RouterUrls.SIGN_UP} element={<Auth />} />
        <Route path={RouterUrls.VERIFICATION} element={<EmailVerification />} />
        <Route path={RouterUrls.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={RouterUrls.TERMS_CONDITIONS} element={<TermsConditions />} />
        <Route path={RouterUrls.TERMS_CONDITIONS + "/:inviteToken"} element={<TermsConditions />} />
        <Route path={"/expiry-link"} element={<ExpiryLinkPage />} />
        <Route path={"/*"} element={<NotFound />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
