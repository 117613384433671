import { toast } from "@/components/ui/toast/use-toast";
import { RouterUrls } from "constant/enums/routes.enum";
import { WebApiEndPointUrls } from "constant/enums/webapi-endpoint.enum";
import { axiosInstance } from "utils/axios/axios-instance.utils";

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isEmpty = (value: string) => {
  return value === undefined || value === null || value === "";
};

export const isNotEmpty = (value: string) => {
  return !isEmpty(value);
};

export const logoutHelper = async (successIcon, failiureIcon) => {
  try {
    const res = await axiosInstance.post(`${WebApiEndPointUrls.LOGOUT_ACCOUNT}`);
    toast({
      description: res?.data?.data,
      icon: successIcon,
      className: "bg-[#ffffff]"
    });
    window.location.pathname = "/";
  } catch (err: any) {
    toast({
      title: err?.message,
      icon: failiureIcon,
      className: "bg-[#ffffff]"
    });
  }
};

export const ERROR_CODES = {
  ERROR001: {
    title: "Something went wrong",
    description: "Something went wrong. Please try after sometime."
  },
  ERROR002: {
    title: "Invitation is expired",
    description: "The link is not valid. Please contact admin to resend invite."
  },
  ERROR003: {
    title: "Invitation already accepted",
    description: "Invitation already accepted. Please continue to account."
  },
  ERROR004: {
    title: "User not found",
    description: "User not found. Please contact your admin."
  },
  ERROR005: {
    title: "Something went wrong",
    description: "Something went wrong. Please try after sometime."
  }
};

export const fetchErrorCode = (errorCode) => {
  if (!errorCode) {
    return {
      title: "Something went wrong",
      description: "Something went wrong. Please try after sometime."
    };
  }
  let objErrorCode = ERROR_CODES[errorCode];
  if (!objErrorCode) {
    objErrorCode = {
      title: "Something went wrong",
      description: "Something went wrong. Please try after sometime."
    };
  }
  return objErrorCode;
};

export const isManageTeamEnabled = (permissions: string[] = [], path: string) => {
  return path === RouterUrls.MANAGE_TEAM && permissions.findIndex((permission) => permission === "INVITE_MANAGE_TEAM") > -1;
};

export const CONTACT_US_MAIL = "uma@feedbackhero.ai"