/*
  TODO: 
    - Handle report button click
    - Handle send to candidate button click
    - Handle send message from library button click
*/

import React, { useEffect, useState } from 'react'
import ReviewerFeedbackContent from './reviewerFeedbackContent'
import { IReviewerFeedbackProps } from './reviewerFeedback.types'
import Avatar from 'atoms/avatar'
import ApplicationEditor from 'components/application-editor'
import Tooltip from 'atoms/tooltip'
import ThreeDotMenu from 'atoms/three-dot-menu'
import { IOptionItem } from 'atoms/three-dot-menu/threeDotMenu.types'
import { ArrowUturnLeftIcon, FlagIcon } from '@heroicons/react/24/outline'
import HtmlRenderer from './htmlRenderer'
import { classNames } from 'utils/string'
import ReviewerFeedbackLoadingSkeleton from './reviewerFeedbackLoadingSkeleton'

const ReviewerFeedback: React.FC<IReviewerFeedbackProps> = ({ 
    candidateName, 
    candidateImage, 
    jobName, 
    reviewerName, 
    reviewerImage, 
    reviewerFeedbackText, 
    timeString, 
    isLoading, 
    originalAIFeedback
  }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [AIFeedback, setAIFeedback] = useState("");
  const [AIFeedbackBeforeEditing, setAIFeedbackBeforeEditing] = useState("");

  useEffect(() => {
    setAIFeedback(originalAIFeedback);
    setAIFeedbackBeforeEditing(originalAIFeedback);
  }, [isLoading])

  const threeDotMenuOptions: IOptionItem[] = [
    {
      icon: <ArrowUturnLeftIcon className="h-6 w-6 text-[#7A7A7A]" />,
      optionText: "Revert to original",
      handleClick: () => handleRevertToOriginal(),
    },
    {
      icon: <FlagIcon className="h-6 w-6 text-[#7A7A7A]" />,
      optionText: "Report",
      handleClick: () => alert("Remove member clicked") // Needs to be updated
    }
  ];

  const handleEditClick = () => {
    setIsEditing(true);
    setAIFeedbackBeforeEditing(AIFeedback);
  }

  const handleCancelEditClick = () => {
    setAIFeedback(AIFeedbackBeforeEditing);
    setIsEditing(false);
  }

  const handleSaveClick = () => {
    setIsEditing(false);
  }

  const handleRevertToOriginal = () => {
    setAIFeedback(originalAIFeedback);
  }

  return (
    isLoading ?
      <ReviewerFeedbackLoadingSkeleton/>
    :
      <div className='w-full h-full relative flex flex-col justify-between'>
        <div>

          {/* Header */}
          <div className='sticky top-0 w-full pb-3 pt-4 text-xl border-b border-borderSecondary mb-8 font-medium text-[#4A4C52] bg-white opacity-100'>Send personalized feedback!</div>

          {/* Candidate Details */}
          <div className='flex mb-6 sticky top-[57px] w-full bg-white opacity-100 py-2'>
            <Avatar userName={candidateName} imageSrc={candidateImage} />
            <div className='flex flex-col justify-around ml-3'>
              <div className='text-sm text-gray-900 font-medium'>{candidateName}</div>
              <div className='flex text-[12px]'>
                <div className='text-[#6B7280] mr-2'>Job name:</div>
                <div className='text-[#4A4C52]'>{jobName}</div>
              </div>
            </div>
          </div>
          
          {/* Feedback from Reviewer */}
          <div className='mb-8'>
            <ReviewerFeedbackContent reviewerName={reviewerName} reviewerImage={reviewerImage} reviewerFeedbackText={reviewerFeedbackText} timeString={timeString} />
          </div>

          {/* AI Feedback */}
          <div className='border border-borderSecondary rounded-md p-3'>

            <div className='flex justify-between'>
              <div className='flex items-center'>
                <div className='text-sm font-medium mr-3'>AI ✨ feedback</div>
                <Tooltip variant='light'>
                  <>
                    <p className='mb-4'>
                      {"Dynamic data: You can dynamically pull the candidate name, role name, and company name into the template by typing out or copy pasting the following into the text editor - {{Role name}} , {{Company name}}, and {{Candidate name}}"}
                    </p>
                    <p>
                      This exact text must be used and capitalization must match.
                    </p>
                  </>
                </Tooltip>
              </div>
              <div className='flex items-center'>
                <button className='border border-[#4A4C52] rounded-[5px] text-[12px] font-medium py-[6px] px-6 mr-6 flex items-center' onClick={handleEditClick}><p>Edit</p></button>
                <div className='pt-2 mr-2'>
                  <ThreeDotMenu options={threeDotMenuOptions} variant="horizontal" />
                </div>
              </div>
            </div>

            <div>
              {isEditing? 
                <ApplicationEditor content={AIFeedback} setHTMLContent={setAIFeedback} /> :
                <HtmlRenderer htmlContent={AIFeedback} />
              }
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className={classNames('sticky bottom-0 w-full bg-white opacity-100 flex py-4', (isEditing? 'justify-between' : 'justify-end'))}>
          <div className='flex'>
            <button className='border border-[#4A4C52] rounded-md py-2 px-4 text-sm mr-3 font-medium text-[#4A4C52] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark'>Send message from library</button>
            <button className={classNames("rounded-md py-2 px-4 text-sm font-medium bg-primary text-fontDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark",isEditing? "": "hidden")} onClick={handleSaveClick}>Save</button>
          </div>
          <div>
            <button className={classNames("rounded-md bg-white px-4 py-2 text-sm text-gray-900 mr-3 font-medium hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark",isEditing? "": "hidden")} onClick={handleCancelEditClick} >Cancel Edit</button>
            <button className='rounded-md py-2 px-4 text-sm font-medium bg-primary text-fontDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark'>Send to candidate</button>
          </div>
        </div>

      </div>
    
  )
}

export default ReviewerFeedback