import React, { useState } from 'react'
import { IAvatarProps } from './avatar.types';
import { UserIcon } from '@heroicons/react/20/solid';



const Avatar: React.FC<IAvatarProps> = ({ userName, imageSrc, width="w-10", height="h-10", initialsSize="text-2xl" }) => {

    const [imageError, setImageError] = useState(false);

    const avatarWithImage = (
        <img src={imageSrc} alt="Image" onError={() => setImageError(true)} className={`${width} ${height} rounded-full`} />
    )

    const userNameInitials = () => {
        return userName?  userName[0].toUpperCase() : <UserIcon className="h-6 w-6"  />;
    }

    const avatarWithInitials = (
        <div className={`${width} ${height} rounded-full border-2 ${initialsSize} leading font-medium flex items-center justify-center bg-[#F4F4F4]`}>
            <div>
                {userNameInitials()}
            </div>
        </div>
    )

  return (
    <div>
        {(!imageError && imageSrc) && avatarWithImage}
        {(imageError || !imageSrc) && avatarWithInitials}
    </div>
  )
}

export default Avatar