import React from 'react'

import { ArrowLeftIcon } from '@heroicons/react/24/outline'

const GoBackButton = ({handleClick}) => {
  return (
    <button className='flex items-center' onClick={handleClick}>
        <ArrowLeftIcon className='h-4 w-4 text-[#4A4C52] font-medium stroke-2'/>
        <p className='pl-2 text-[16px] text-[#4A4C52] font-medium'>Go Back</p>
    </button>
  )
}

export default GoBackButton