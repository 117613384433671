import Bold from '@tiptap/extension-bold'
import Link from '@tiptap/extension-link'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Italic from '@tiptap/extension-italic'
import BulletList from '@tiptap/extension-bullet-list'
import { EditorContent, useEditor } from '@tiptap/react'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import History from '@tiptap/extension-history'
import ApplicationEditorToolbar from './applicationEditorToolbar'
import { IApplicationEditorProps } from './applicationEditor.types'
import React, { useState } from 'react'
import ApplicationEditorBubbleMenu from './applicationEditorBubbleMenu'

const ApplicationEditor: React.FC<IApplicationEditorProps> = ({content, setTextContent, setHTMLContent, setJSONContent}) => {
  
    const [showBubbleMenu, setShowBubbleMenu] = useState(false);
    const [linkInput, setLinkInput] = useState("")

    const editor = useEditor({
      extensions: [Document, 
        Paragraph.configure({
          HTMLAttributes: {
            class: 'm-0',
          },
        }), 
        Text, 
        Bold,
        Italic,
        BulletList.configure({
          HTMLAttributes: {
            class: 'm-0'
          }
        }), 
        OrderedList.configure({
          HTMLAttributes: {
            class: 'm-0'
          }
        }),
        ListItem,
        History, // For undo and redo
        Link.configure({
          openOnClick: false,
          autolink: false,
        }),
        ],
        editorProps: {
          attributes: {
            class: 'focus:outline-none min-h-24',
          },
        },
      content: (content? content : ""),
    })

    if (!editor) {
      return null
    }
    
    function debounce<T extends (...args: any[]) => void>(
        callbackFunction: T,
        delay = 500
    ): (...args: Parameters<T>) => void {
        let timeout: NodeJS.Timeout;
    
        return (...args: Parameters<T>) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                callbackFunction(...args);
            }, delay);
        };
    }
    

    const handleContentUpdate = debounce(() => {
      if(setHTMLContent) setHTMLContent(editor.getHTML())
      if(setTextContent) setTextContent(editor.getText())
      if(setJSONContent) setJSONContent(editor.getJSON())
    })

    editor.on('update', handleContentUpdate)
  
    return (
      <div className='w-full'>
        {editor && <ApplicationEditorBubbleMenu editor={editor} linkInput={linkInput} setLinkInput={setLinkInput} setShowBubbleMenu={setShowBubbleMenu} showBubbleMenu={showBubbleMenu} />}
        <EditorContent className='text-[#4A4C52] text-sm prose prose-a:text-teal-600 max-w-full w-full' editor={editor}/>
        <ApplicationEditorToolbar editor={editor} setLinkInput={setLinkInput} showBubbleMenu={showBubbleMenu} setShowBubbleMenu={setShowBubbleMenu}/>
      </div>
    )
}


export default ApplicationEditor