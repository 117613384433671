import { useCallback, useEffect, useRef, useState } from "react";

const useTimer = (ttl: number, callback?: () => void, interval = 1000) => {
  const [timeRemaining, setTimeRemaining] = useState<number>(ttl);
  const timeoutRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const startTimer = useCallback(() => {
    timeoutRef.current = setInterval(() => setTimeRemaining((timeRemaining) => timeRemaining - 1), interval);
  }, [setTimeRemaining]);

  const reset = useCallback(() => {
    setTimeRemaining(ttl);
    startTimer();
  }, [startTimer]);

  const stop = useCallback(() => {
    setTimeRemaining(0);
    clearInterval(timeoutRef.current!);
  }, []);

  useEffect(() => {
    startTimer();
  }, [setTimeRemaining]);

  useEffect(() => {
    if (timeRemaining === 0) {
      clearInterval(timeoutRef.current!);
      callback?.();
    }
  }, [timeRemaining, callback]);

  return { timeRemaining, reset, stop };
};

export default useTimer;
