import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
    <div className='bg-[#121212] w-full mx-auto text-[#ffffff] font-medium py-20 text-center text-4xl md:text-6xl font-sans'>
        Privacy Policy
    </div>
    <div className="container mx-auto px-8 lg:px-40 py-8">

        <h2 className="text-2xl font-medium mb-2">Information we collect</h2>
        <p className="mb-4 text-[#4A4C52]">
            We may collect personal information such as your name, email address, contact information, and other data when you interact with our website or services. This information is collected for the purpose of providing and improving our services, responding to your inquiries, and enhancing your user experience.
        </p>

        <h2 className="text-2xl font-medium mb-2">How we use your information</h2>

        <p className="mb-4 text-[#4A4C52]">
            We may use your personal information for the following purposes:
        </p>
        <ul className="list-disc list-inside mb-4 text-[#4A4C52]">
            <li>To provide and maintain our services.</li>
            <li>To communicate with you, respond to your inquiries, and provide customer support.</li>
            <li>To improve our services and develop new features.</li>
            <li>To send you updates, promotions, and other relevant information.</li>
        </ul>

        <h2 className="text-2xl font-medium mb-2">Data security</h2>
        <p className="mb-4 text-[#4A4C52]">
            We employ robust security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. We regularly review and update our security protocols to ensure the safety of your data.
        </p>

        <h2 className="text-2xl font-medium mb-2">Third-party links</h2>
        <p className="mb-4 text-[#4A4C52]">
            Our website and services may contain links to third-party websites or services. We are not responsible for the content or privacy practices of these third parties. We encourage you to read their privacy policies before providing any personal information.   
        </p>

        <h2 className="text-2xl font-medium mb-2">Cookies</h2>
        <p className="mb-4 text-[#4A4C52]">
            We may use cookies and similar tracking technologies to enhance your experience on our website. You have the option to manage or delete cookies through your browser settings.
        </p>

        <h2 className="text-2xl font-medium mb-2">Your Right</h2>
        <p className="mb-4 text-[#4A4C52]">
            You have the right to access, correct, update, or delete your personal information. If you wish to exercise these rights, please contact us using the information below.
        </p>

        <h2 className="text-2xl font-medium mb-2">Changes to this Privacy Policy</h2>
        <p className="mb-4 text-[#4A4C52]">
            We reserve the right to update or modify this Privacy Policy at any time. Any changes will be reflected on this page, along with the effective date.
        </p>

    </div>
    </div>
  )
}

export default PrivacyPolicy