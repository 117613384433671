import React from 'react'
import { classNames } from 'utils/string';
import { ITooltipProps } from './tooltip.types';
import TooltipWrapper from './tooltipWrapper';

const Tooltip: React.FC<ITooltipProps> = ({ children, variant }) => {

  return (
      <TooltipWrapper tooltipContent={children} variant={variant}>
        <button 
          className={classNames(`rounded-full py-1 px-[9px]  bg-gray-500 flex justify-center items-center`, (variant == "dark")? "text-fontDark" : "text-white")}
        >
          <span className='font-medium text-xs leading-none mt-[2px]'>i</span>
        </button>
      </TooltipWrapper>
    );
}

export default Tooltip