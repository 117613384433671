import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/20/solid'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { IBreadCrumbsProps } from './breadCrumbs.types'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { classNames } from 'utils/string'

const BreadCrumbs: React.FC<IBreadCrumbsProps> = ({ firstBreadCrumbTitle, firstBreadCrumbLink, breadCrumbsList }) => {

    const [isListOpen, setIsListOpen] = useState(false);
    let listIsOpen = false;

    const getMiddleBreadCrumbs = (

        breadCrumbsList.length <= 2 ?
                breadCrumbsList?.map((breadCrumb, index) => (
                    <>
                        <div className='mx-4'>
                            <ChevronRightIcon className='w-4 h-4 text-fontGrey1'/>
                        </div>
                        <Link to={breadCrumb.link} className={classNames(`text-gray-700 text-sm hover:text-gray-900 text-nowrap`, (index === breadCrumbsList.length - 1)? "truncate":"")}>
                            {breadCrumb.title}
                        </Link>
                    </>
                ))
            
        :
        
            <>
                <div className='mx-4'>
                    <ChevronRightIcon className='w-4 h-4 text-fontGrey1'/>
                </div>
                <div 
                    className='relative items-center' 
                    tabIndex={0} 
                    onFocus={() => {
                        setIsListOpen(true);
                        listIsOpen = true;
                    }} 
                    onBlur={() => {
                        setIsListOpen(false)
                        listIsOpen = false;
                    }}
                >
                    <button 
                        className='p-1' 
                        onClick={() => {
                            setIsListOpen(!listIsOpen)
                            listIsOpen = !listIsOpen;
                        }}
                    >
                        <EllipsisHorizontalIcon className='h-6 w-6 mt-1 text-gray-700' />
                    </button>
                    {isListOpen && <div className='absolute z-10 flex flex-col rounded-md py-1 bg-gray-100' >
                        {(breadCrumbsList.slice(0, -1).map(breadCrumb => (
                            <Link to={breadCrumb.link} className='text-nowrap text-gray-700 text-sm hover:text-gray-900 text-left border-b border-white bg-gray-100 px-2 py-2 hover:bg-gray-200 w-48 sm:w-full truncate'>{breadCrumb.title}</Link>
                        )))}
                    </div>}
                </div>
                <div className='mx-4'>
                    <ChevronRightIcon className='w-4 h-4 text-fontGrey1'/>
                </div>
                <Link to={breadCrumbsList[breadCrumbsList.length - 1].link} className='text-gray-700 text-sm hover:text-gray-900 text-nowrap truncate'>
                    {breadCrumbsList[breadCrumbsList.length - 1].title}
                </Link>
            </>
    )


  return (
    <div className='flex items-center max-w-full'>
        <Link to={firstBreadCrumbLink} className='flex items-center text-gray-700 hover:text-gray-900'>
            <HomeIcon className='w-5 h-5 text-fontDark'/>
            <p className='pl-4 text-sm hidden sm:block'>{firstBreadCrumbTitle}</p>
        </Link>
        {getMiddleBreadCrumbs}
    </div>
  )
}

export default BreadCrumbs