import React, { useEffect } from 'react'
import { ISearchResultsListProps } from './searchBar.types'
import { classNames } from 'utils/string'

const SearchResultsList: React.FC<ISearchResultsListProps> = ({searchResult, activeIndex, handleOptionClick, setIsDropdownFocused }) => {

  useEffect(() => {
    setIsDropdownFocused(true)
  },[])

  return (
    <div className='absolute z-[70] py-2 w-full' onFocus={() => setIsDropdownFocused(true)} onBlur={() => setIsDropdownFocused(false)} >
      {searchResult.map((result, index) => (
        <button className={classNames((activeIndex === index ? "bg-gray-100 " : "bg-gray-50 "),' pl-8 py-2 w-full text-left text-[16px] text-gray-700 hover:bg-gray-100 border-b-[1px] border-gray-200 truncate')} onClick={() => handleOptionClick(index)} key={index}>
          {result}
        </button>
      ))}
    </div>
  )
}

export default SearchResultsList