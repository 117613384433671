import { useToast } from "@/components/ui/toast/use-toast";
import { Button, Input } from "atoms";
// import AlertModal from "components/alertModal";
import Confirm from "components/confirm";
import HeadingWithDescription from "components/headingWithDescription";
import { BtnText } from "constant/enums/btn-txt.enum";
import { WebApiEndPointUrls } from "constant/enums/webapi-endpoint.enum";
import { INVALID_EMAIL } from "constant/texts/login";
// import useConfirm from "hooks/useConfirm";
import { ChangeEvent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { axiosInstance } from "utils/axios/axios-instance.utils";
import { isNotEmpty, validateEmail } from "utils/common/common-utils";

const initialForm = (role) => [
  {
    key: "firstName",
    label: "First Name",
    optional: false,
    placeholder: "",
    value: "",
    error: "",
    span: false,
    disabled: false,
    capitalize: false,
    type: "text"
  },
  {
    key: "middleName",
    label: "Middle Name",
    optional: true,
    placeholder: "",
    value: "",
    error: "",
    span: false,
    disabled: false,
    capitalize: false,
    type: "text"
  },
  {
    key: "lastName",
    label: "Last name",
    optional: false,
    placeholder: "",
    value: "",
    error: "",
    span: true,
    disabled: false,
    capitalize: false,
    type: "text"
  },
  {
    key: "email",
    label: "Email address",
    optional: false,
    placeholder: "",
    value: "",
    error: "",
    span: false,
    disabled: true,
    capitalize: false,
    type: "email"
  },
  {
    key: "alternateEmail",
    label: "Alternative email address",
    optional: true,
    placeholder: "",
    value: "",
    error: "",
    span: false,
    disabled: false,
    capitalize: false,
    type: "email"
  },
  {
    key: "companyName",
    label: "Company Name",
    optional: false,
    placeholder: "",
    value: "",
    error: "",
    span: true,
    capitalize: true,
    disabled: role === "OWNER" ? false : true,
    type: "text"
  }
];
  
const ManageUserData = () => {
  const [cookies] = useCookies();
  const [formState, setFormState] = useState(initialForm(cookies["role"]));
  const [initialFormState, setInitialFormState] = useState(initialForm(cookies["role"]));
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = async () => {
    const res = await axiosInstance.get(WebApiEndPointUrls.USER_ACCOUNT);
    if (res.data?.data) {
      const updatedFormState = formState.map((formElement) => ({
        ...formElement,
        value: res.data.data[formElement.key] === null ? "" : res.data.data[formElement.key]
      }));
      setInitialFormState(updatedFormState);
      setLoading(true);
    }
  };

  useEffect(() => {
    const updatedFormState = initialFormState.map((formElement) => ({
      ...formElement
    }));
    setFormState(updatedFormState);
  }, [initialFormState]);

  useEffect(() => {
    setIsFormEdited(JSON.stringify(formState) !== JSON.stringify(initialFormState));
  }, [formState, initialFormState]);

  /**
   * Call Patch API to update the account details
   *
   */
  const updateAccountDetails = async () => {
    /**
     * Form Validation.
     */
    let isFormValid = true;
    const updatedFormState = formState.map((element) => {
      const isValid = isNotEmpty(element.value) ? (element.type === "email" ? !!validateEmail(element.value) : true) : element.optional;
      isFormValid = isFormValid && isValid;
      return {
        ...element,
        error: isValid ? "" : element.type === "email" ? INVALID_EMAIL : "This is a required field"
      };
    });
    /**
     * Updating error state of the fields
     */
    setFormState(updatedFormState);

    /**
     * Sending patch request to BE.
     */

    if (isFormValid) {
      const requestBody = {};
      formState.forEach((element) => {
        if (!element.disabled) requestBody[element.key] = element.value;
      });
      try {
        await axiosInstance.patch(WebApiEndPointUrls.USER_ACCOUNT, requestBody, {
          withCredentials: true
        });
        const updatedFormState = formState.map((formElement) => ({
          ...formElement
        }));
        setInitialFormState(updatedFormState);
        toast({
          description: "Details updated successfully.",
          variant: "success"
        });
      } catch (error) {
        toast({
          description: "Something went wrong.",
          variant: "error"
        });
      }
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const formElementIndex = formState.findIndex((element) => element.key === event.target.name);
    if (formElementIndex > -1) {
      setIsFormEdited(true);
      const updatedFormElement = { ...formState[formElementIndex], value: event.target.value, error: "" };
      const updatedFormState = [...formState];
      updatedFormState[formElementIndex] = updatedFormElement;
      setFormState(updatedFormState);
    }
  };

  const resetHandler = () => {
    const updatedFormState = initialFormState.map((formElement) => ({
      ...formElement
    }));
    setFormState(updatedFormState);
    setIsCancelled(false);
  };

  const cancelHandler = () => {
    setIsCancelled(false);
  };

  // const [showDiscardPopup, setShowDiscardPopup] = useState(false);

  const [isCancelled, setIsCancelled] = useState(false);

  return (
    loading && (
      <>
        <section className="flex flex-col">
          <HeadingWithDescription heading="Manage Settings" />
          <div className="flex mt-8 flex-col gap-8">
            <Confirm isDirty={isFormEdited} isCancelled={isCancelled} cancelHandler={cancelHandler} resetHandler={resetHandler}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-[100%] sm:w-[90%] md:w-[80%] lg:w-[70%]">
                {formState.map((formElements) => (
                  <div className={`grid grid-cols-subgrid ${formElements.span ? "sm:col-span-2" : ""}`}>
                    <Input
                      disabled={formElements.disabled}
                      key={formElements.key}
                      label={formElements.optional ? formElements.label + " (Optional)" : formElements.label}
                      error={formElements.error}
                      name={formElements.key}
                      placeholder={formElements.placeholder}
                      value={formElements.value}
                      style={{ textTransform: formElements.capitalize ? "capitalize" : undefined }}
                      onChange={changeHandler}
                      type={formElements.type}
                    />
                  </div>
                ))}
              </div>
            </Confirm>
            <div className="flex justify-end gap-4 w-[100%] sm:w-[94%] md:w-[84%] lg:w-[74%]">
              {isFormEdited && (
                <Button variant="tertiary" onClick={() => setIsCancelled(true)}>
                  {BtnText.CANCEL}
                </Button>
              )}
              <Button onClick={updateAccountDetails} disabled={!isFormEdited}>
                {BtnText.SAVE}
              </Button>
            </div>
          </div>
        </section>
        {/* <AlertModal
          title="Discard"
          variant="error"
          open={showDiscardPopup}
          onClose={() => setShowDiscardPopup(false)}
          footerButton={
            <>
              <Button variant="danger" customClasses="sm:ml-3 w-full sm:w-auto" onClick={resetForm}>
                {BtnText.DISCARD}
              </Button>
              <Button variant="secondary" customClasses="mt-3 sm:mt-0 w-full sm:w-auto" onClick={() => setShowDiscardPopup(false)}>
                {BtnText.KEEP}
              </Button>
            </>
          }
        >
          <p>Are you sure you want to discard the changes you made?</p>
        </AlertModal> */}
      </>
    )
  );
};

export default ManageUserData;
