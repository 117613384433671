import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "utils/string";
import { ISelectProps } from "./select.types";
import Tooltip from "atoms/tooltip";

function Select<T extends { label: string; id: string | number }>({
  options,
  selected,
  onChange,
  label,
  placeholder = "Please select",
  showTooltip = false,
  toolTipChildren = "",
  ...props
}: ISelectProps<T>) {
  return (
    <section {...props}>
      <Listbox value={selected} onChange={(value) => onChange(value as T)}>
        {({ open }) => (
          <>
            <Listbox.Label className="flex gap-1 align-center text-sm font-medium leading-6 text-gray-900">
              {label}
              {showTooltip && toolTipChildren && <Tooltip variant={"light"}>{toolTipChildren}</Tooltip>}
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full h-[35px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-fontDark sm:text-sm sm:leading-6">
                <span className="block truncate">{selected?.label || placeholder}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option?.id}
                      className={({ active }) =>
                        classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-900", "relative cursor-default select-none py-2 pl-3 pr-9")
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>{option?.label}</span>

                          {selected ? (
                            <span
                              className={classNames(active ? "text-fontDark" : "text-fontDark", "absolute inset-y-0 right-0 flex items-center pr-4")}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </section>
  );
}

export default Select;
