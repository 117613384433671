// import { useState } from "react";
import { LinkIcon as LinkIconFilled } from '@heroicons/react/20/solid'
import { ListBulletIcon as ListBulletIconFilled } from '@heroicons/react/20/solid'
import { classNames } from 'utils/string'

const ApplicationEditorToolbar = ({editor, showBubbleMenu, setShowBubbleMenu, setLinkInput}) => {
  
    const setLink = () => {
      if(!editor){
          return;
      }

      // from: Start position of selected text
      // to: Last position of selected text
      const {from, to} = editor.state.selection;
    
      if(showBubbleMenu){
        setShowBubbleMenu(false);
        return;
      }

      // Make BubbleMenu visible
      setShowBubbleMenu(true)
      editor.commands.setTextSelection({from: from, to: to})
      editor.commands.focus()

      // If there is text attached to the text
      // then change the state of linkInput to that
      const previousUrl = editor.getAttributes('link').href
      setLinkInput(previousUrl? previousUrl : "")
    }
  
    return (
      <div>

        {/* Toolbar Options  */}
        <div className='flex border-2 border-[#E6E6E6] mt-1 px-1 py-1 items-center justify-between'>
          
          <div className='flex items-center'>
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              className={classNames(editor.isActive('bold') ? 'bg-[#E6E6E6]' : '', 'font-bold text-sm px-2 py-1 mr-3 rounded-sm')}
            >
              B
            </button>
  
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={classNames(editor.isActive('italic') ? 'bg-[#E6E6E6]' : '', 'text-md px-[10px] py-[3px] mr-3 rounded-sm font-serif')}
            >
              I
            </button>
  
            {/* Seperator  */}
            <div className='h-[16px] border-l-2 border-gray-300 mr-3'></div>
  
            <button 
              onClick={setLink} className={classNames((showBubbleMenu || editor.isActive('link'))? 'bg-[#E6E6E6]': '','px-1 py-[6px] mr-3 rounded-sm')}
            >
              <LinkIconFilled className={`h-4 w-4 shrink-0 text-gray-800 `}/>
            </button>
  
            {/* Seperator  */}
            <div className='h-[16px] border-l-2 border-gray-300 mr-3'></div>
  
            <button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={classNames(editor.isActive('bulletList') ? 'bg-[#E6E6E6]' : '', 'text-sm px-[6px] py-[6px] mr-3 rounded-sm')}
            >
              <ListBulletIconFilled className={`h-4 w-4 shrink-0 text-gray-800 `}/>
            </button>
  
            <button
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={classNames(editor.isActive('orderedList') ? 'bg-[#E6E6E6]' : '', 'text-sm px-[6px] py-[6px] mr-3 rounded-sm')}
            >
              <svg viewBox="0 0 1920 1920" className={`h-4 w-4 shrink-0 text-gray-800 `} xmlns="http://www.w3.org/2000/svg">
                  <path d="M169.412 1355.294c93.402 0 169.412 76.01 169.412 169.412 0 43.595-16.942 82.899-44.16 112.941 27.218 30.042 44.16 69.346 44.16 112.941 0 93.403-76.01 169.412-169.412 169.412H0v-112.941h169.412c31.059 0 56.47-25.412 56.47-56.47 0-31.06-25.411-56.471-56.47-56.471H0v-112.942h169.412c31.059 0 56.47-25.411 56.47-56.47 0-31.059-25.411-56.47-56.47-56.47H0v-112.942ZM1920 1468.235v112.941H564.706v-112.94H1920ZM188.058 677.67c61.78 0 118.814 38.4 145.356 97.694 28.8 64.037 15.36 136.546-35.916 194.033-16.286 18.262-34.108 37.88-52.187 57.582l-3.101 3.377c-2.07 2.252-4.14 4.505-6.213 6.755l-3.108 3.374-3.107 3.37c-28.478 30.87-56.688 61.043-79.672 85.58h188.725v112.94H56.482c-31.285 0-56.47-25.298-56.47-56.47v-39.53c0-14.456 5.533-28.46 15.585-38.964.113-.113 117.459-123.558 197.647-213.233 21.346-23.944 27.445-49.807 17.167-72.621-8.131-18.297-25.637-30.946-42.353-30.946-40.546 0-82.898 48.452-94.418 65.506L.01 792.983C7.804 781.237 80.425 677.67 188.058 677.67ZM1920 903.53v112.94H564.706V903.53H1920ZM169.412 0c31.172 0 56.47 25.299 56.47 56.47v508.236h-112.94V112.94H0V0ZM1920 338.824v112.94H564.706v-112.94H1920Z" fill-rule="evenodd"/>
              </svg>
            </button>
            
          </div>  
        </div>
      </div>
    )
  }
  
  export default ApplicationEditorToolbar