import DataTable from "react-data-table-component";
import { ITableProps } from "./table.types";

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#F9FAFB",
      fontSize: "12px",
      fontWeight: 500,
      color: "#4B5563"
    }
  },
  responsiveWrapper: {
    style: {
      borderRadius: "20px"
    }
  }
};

const Table: React.FC<ITableProps> = ({
  columns,
  data,
  fetchData,
  totalRows,
  loading,
  rowsPerPage,
  setRowsPerPage,
  paginationPerPage,
  paginationRowsPerPageOptions,
  pagination = true,
  ...props
}) => {
  const handlePerRowsChange = async (newPerPage, page) => {
    await fetchData(page, newPerPage);
    setRowsPerPage(newPerPage);
  };

  const handlePageChange = async (page) => {
    await fetchData(page, rowsPerPage);
  };

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={customStyles}
      progressPending={loading}
      pagination={pagination}
      paginationServer
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationPerPage={paginationPerPage}
      sortIcon={
        <div className="sort-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18" fill="none">
            <path
              d="M16.1546 5.74017V14.3503M3.0437 2.87012H15.2181H3.0437ZM3.0437 5.74017H11.4722H3.0437ZM3.0437 8.61023H8.66268H3.0437ZM12.4087 8.61023L16.1546 5.74017L12.4087 8.61023ZM16.1546 5.74017L19.9006 8.61023L16.1546 5.74017Z"
              stroke="#4D4D4D"
              stroke-width="1.07627"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      }
      paginationRowsPerPageOptions={paginationRowsPerPageOptions}
      {...props}
    />
  );
};

export default Table;
