// external imports
import { ChangeEvent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";

// In house imports
import { APP_TAG_LINE } from "constant/texts/login";
import { BtnText } from "constant/enums/btn-txt.enum";
import { Input } from "atoms";
import { axiosInstance } from "utils/axios/axios-instance.utils";
import { WebApiEndPointUrls } from "constant/enums/webapi-endpoint.enum";

/**
 * Purpose : Complete the onboarding process and user can update
 * the account details.
 *
 * It will redirects user to home page after successful onboarding
 * completion
 *
 */

const initialFormState = (role) => [
  {
    key: "firstName",
    label: "First Name",
    optional: false,
    placeholder: "ex. Stuart",
    value: "",
    error: "",
    capitalize: false,
    disabled: false
  },
  {
    key: "middleName",
    label: "Middle Name",
    optional: true,
    placeholder: "ex. Leonard",
    value: "",
    error: "",
    capitalize: false,
    disabled: false
  },
  {
    key: "lastName",
    label: "Last name",
    optional: false,
    placeholder: "ex. Jackson",
    value: "",
    error: "",
    capitalize: false,
    disabled: false
  },
  {
    key: "companyName",
    label: "Company",
    optional: false,
    placeholder: "ex. Amazon",
    value: "",
    error: "",
    capitalize: true,
    disabled: role === "OWNER" ? false : true
  }
];

const OnboardingStep1 = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const [formState, setFormState] = useState(initialFormState(cookies["role"]));

  useEffect(() => {
    if (cookies) {
      const updatedFormState = formState.map((element) => {
        return {
          ...element,
          value: cookies[element.key] === undefined || cookies[element.key] === "undefined" ? "" : cookies[element.key]
        };
      });
      setFormState(updatedFormState);
    }
  }, []);

  /**
   * Call Patch API to update the account details
   *
   */
  const submitHandler = async () => {
    /**
     * Form Validation.
     */
    let isFormValid = true;
    const updatedFormState = formState.map((element) => {
      const isValid = element.optional ? true : element.value !== "";
      isFormValid = isFormValid && isValid;
      return {
        ...element,
        error: isValid ? "" : "This is a required field"
      };
    });
    /**
     * Updating error state of the fields
     */
    setFormState(updatedFormState);

    /**
     * Sending patch request to BE.
     */

    if (isFormValid) {
      const requestBody = {};
      formState.forEach((element) => {
        if (!element.disabled) requestBody[element.key] = element.value;
      });
      try {
        await axiosInstance.patch(WebApiEndPointUrls.USER_ACCOUNT, requestBody, {
          withCredentials: true
        });
        navigate("/onboarding/step-2");
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const formElementIndex = formState.findIndex((element) => element.key === event.target.name);
    if (formElementIndex > -1) {
      const updatedFormElement = { ...formState[formElementIndex], value: event.target.value, error: "" };
      const updatedFormState = [...formState];
      updatedFormState[formElementIndex] = updatedFormElement;
      setFormState(updatedFormState);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="border-solid border border-grey w-[35rem] m-*:auto rounded-3xl">
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 items-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-14 w-auto" src="/logo.png" alt="Feedback Hero" />
            <h2 className="mt-4 text-center text-[#182021] font-normal text-base">{APP_TAG_LINE}</h2>
          </div>
          <div className="mt-8 w-full">
            <div className="space-y-6">
              {formState.map((formElements) => (
                <Input
                  key={formElements.key}
                  label={formElements.optional ? formElements.label + " (Optional)" : formElements.label}
                  error={formElements.error}
                  name={formElements.key}
                  disabled={formElements.disabled}
                  style={{ textTransform: formElements.capitalize ? "capitalize" : undefined }}
                  placeholder={formElements.placeholder}
                  value={formElements.value}
                  onChange={changeHandler}
                />
              ))}
              <div className="mt-6">
                <button
                  className="flex w-full items-center justify-center rounded-[5px] font-medium focus:outline rounded-md bg-primary px-2.5 py-1.5 text-sm font-semibold text-fontDark shadow-sm hover:bg-primaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                  onClick={submitHandler}
                >
                  {BtnText.USER_ACCOUNT}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingStep1;
