import { BubbleMenu } from '@tiptap/react'
import { XMarkIcon } from '@heroicons/react/20/solid'


const ApplicationEditorBubbleMenuContent = ({ editor, showBubbleMenu, setShowBubbleMenu, setLinkInput, linkInput }) => {

    const handleLinkSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const url = linkInput;
  
      // cancelled
      if (url === null) {
        return
      }
  
      // if empty remove the link style from selected item
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run()
        setShowBubbleMenu(false)
        return
      }
  
      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
      setShowBubbleMenu(false)
    }
  
    const handleLinkInputClose = () => {
      setShowBubbleMenu(false)
      editor.setEditable(true)
    }

    return (
        <div className='flex items-center rounded-sm'>
            {showBubbleMenu && <div className='pl-2 py-1 bg-[#E6E6E6] flex items-center rounded-md'>
            <form onSubmit={handleLinkSubmit}>
                <input type="text" className='border-y-2 border-l-2 border-[#E6E6E6] px-1 w-40 rounded-md' placeholder='Enter Link' value={linkInput} onChange={(e) => setLinkInput(e.target.value)}/>
            </form>
            <button className='bg-[#E6E6E6] h-full border-2 border-[#E6E6E6]' onClick={handleLinkInputClose}>
                <XMarkIcon  className={`h-6 w-6 shrink-0 text-gray-600`}/>
            </button>
            </div>}
        </div>
    )
}
const ApplicationEditorBubbleMenu = ({ editor, linkInput, setLinkInput, showBubbleMenu, setShowBubbleMenu }) => {

  return (
    <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
        <ApplicationEditorBubbleMenuContent showBubbleMenu={showBubbleMenu} setShowBubbleMenu={setShowBubbleMenu} editor={editor} linkInput={linkInput} setLinkInput={setLinkInput} />
    </BubbleMenu>
  )
}

export default ApplicationEditorBubbleMenu