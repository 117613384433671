import React from 'react'
import { IDateAtomProps } from './dateAtom.types';

const DateAtom: React.FC<IDateAtomProps> = ({date, ...props}) => {

  const pad = (num) => {
    return num < 10 ? '0' + num : num;
  };

  const getFormattedDate = (date: Date) => {
    // Array of month names
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
  
    // Extracting date components
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = pad(date.getMinutes());
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  
    // Constructing the formatted date string
    return `${month} ${day}, ${year} ${formattedHours}:${minutes}${period}`;
  }


  return (
    <p className='text-gray-500 text-[14px]' {...props}>
        {date? getFormattedDate(date): "N/A"}
    </p>
  )
}

export default DateAtom