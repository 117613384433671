import { all, takeLatest, call, put } from "redux-saga/effects";
import { GET_TEMP_PERSON_DATA } from "../../constant/global-const/action-type.const";
import { IAxiosResponse } from "../../interfaces/axios.model";
import { getTempPersonDataFailiure, getTempPersonDataSuccess } from "../actions/temp-flow.action";
import TempService from "../../services/temp-flow/temp.service";

function* getPersonList(action: any) {
  try {
    const apiResponce: IAxiosResponse = yield call(TempService.GetTempPersonListService, action?.payload?.endPoint);
    yield put(
      getTempPersonDataSuccess({
        tempPersonListData: apiResponce.data
      })
    );
  } catch (err: any) {
    yield put(
      getTempPersonDataFailiure({
        error: err.message
      })
    );
  }
}

function* tempPersonListSaga() {
  yield all([takeLatest(GET_TEMP_PERSON_DATA, getPersonList)]);
}

export default tempPersonListSaga;
