import React, { ComponentType } from 'react'
import { ICardProps } from './card.types'

const Card: ComponentType<ICardProps> = ({ children, ...props }) => {
  return (
    <section className={`p-8 border border-borderPrimary rounded-[10px] ${props.className}`} {...props}>
      {children}
    </section>
  )
}

export default Card
