import React from 'react'

const Logo = () => {
  return (
    <div className="flex h-16 shrink-0 items-center">
        <img className="w-40 mt-4" src="/logo.png" alt="Your Company" />
    </div>
  )
}

export default Logo