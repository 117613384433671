import { Navbar, Sidebar } from 'components'

const Layout = ({ children }) => {
  return (
    <section>
      {/* sidebar */}
      <Sidebar />

      <div className="lg:pl-72">
        {/* navbar */}
        <Navbar />
        
        <main className="py-10 border rounded-[10px] m-6 min-h-[calc(100vh-110px)]">
          <div className="max-w-7xl px-4 sm:px-6 lg:px-8"> {children}</div>
        </main>
      </div>
    </section>
  )
}

export default Layout
