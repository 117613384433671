import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XCircleIcon, ArrowLeftCircleIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { ISlideOverProps, ISlideOverSizeOptions } from './slide-over.types'
import { classNames } from 'utils/string'

const SlideOver: React.FC<ISlideOverProps> = ({open, onClose, size, handleLeftIconClick, handleRightIconClick, children}) => {

    const getSlideOverSize = (size: ISlideOverSizeOptions) => {
        switch (size) {
          case "extraSmall":
            return "w-[250px]"
          case "small":
            return "w-[500px]"
          case "medium":
            return "w-[750px]"
          case "large":
            return "w-[1000px]"
          default: // extra large
            return "w-[1250px]";
        }
    };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex flex-col items-center justify-center p-1 bg-white rounded-l-md">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white p-1"
                        onClick={onClose}
                      >
                        <XCircleIcon className="h-7 w-7 stroke-2 text-zinc-700" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white p-1"
                        onClick={handleLeftIconClick}
                      >
                        <ArrowLeftCircleIcon className="h-7 w-7 stroke-2 text-zinc-700" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white p-1"
                        onClick={handleRightIconClick}
                      >
                        <ArrowRightCircleIcon className="h-7 w-7 stroke-2 text-zinc-700" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className={classNames("flex h-full flex-col overflow-y-scroll bg-white shadow-xl custom-scrollbar", getSlideOverSize(size))}>
                    <div className="relative flex-1 px-4 sm:px-6">
                        {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SlideOver;
