import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: true
});

axiosInstance.interceptors.request.use(async (req: any) => {
  req.baseURL = process.env.REACT_APP_BASE_API_ENDPOINT;
  req.headers["Access-Control-Allow-Origin"] = "*";
  req.headers["Content-Type"] = "application/json";
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance };
