import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const PROPERTYID = process.env.REACT_APP_TAWK_PROPERTYID;
const TAWK_WIDGETID = process.env.REACT_APP_TAWK_WIDGETID;

const TawkChat = () => {
  return (
    <div>
      <TawkMessengerReact
        propertyId={PROPERTYID}
        widgetId={TAWK_WIDGETID}
      />
    </div>
  )
}

export default TawkChat
