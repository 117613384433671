import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import React from 'react'
import { ITextareaProps } from './textarea.types';

const getInputClasses = (error) => {
  if (error) {
    return "text-red-900 ring-red-300 placeholder:text-red-300 ring-1 focus:ring-red-500";
  }
  return "text-gray-500 ring-borderSecondary ring-1 placeholder:text-gray-500 focus:ring-fontDark";
};

const TextArea: React.FC<ITextareaProps> = ({width = "w-[500px]", error = "", placeholder = "", ...props}) => {

  const textareaClass = `p-3 h-24 rounded-md ${width} focus:ring-1 text-[12px] resize-none ${getInputClasses(error)}`

  return (
    <div>
      <div className='relative'>
        <textarea className={textareaClass} placeholder={placeholder} {...props}/>
        {error ? (
          <div className="pointer-events-none absolute bottom-0 right-0 flex items-center pr-3 pb-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>
      {error ? (
        <p className="text-sm text-red-600" id="email-error">
          {error}
        </p>
      ) : null}textareaClass
    </div>
  )
}

export default TextArea