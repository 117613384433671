export const APP_TAG_LINE = "Great candidate experience wins talent";
export const GOOGLE = "Google";
export const LOGIN_HELPER = "We will verify your email via a link to confirm your identity";
export const RESEND = "Resend";
export const WAITING_HEADER = "You’re almost there!";
export const WAITING_HELPER = "Please verify the link sent to";
export const WAITING_HELPER_2 = "This link will expire within 15 Minutes. If you don’t see it in your Inbox, please check your spam folder.";
export const WAITING_HELPER_FOOTER = "We’re here to help.";
export const CONTACT_US = "Contact us";
export const LOGIN_MODE = "login";
export const SIGNUP_MODE = "signup";
export const ONBOARDING_FEEDBACK = "Personalized feedback as easy as 1,2,3!";
export const VERIFICATION_EMAIL_SUCCESS = "Successfully sent verification email";
export const VERIFICATION_EMAIL_FAILIURE = "Error with sending the request. Please try again.";
export const INVALID_EMAIL = "Please enter a valid Email";
export const PLEASE_ENTER_EMAIL = "Please enter an Email";
export const DONT_HAVE_ACCOUNT = "Don't have an account?";
export const ALREDY_HAVE_ACCOUNT = "Have an account already?";
export const ENTER_EMAIL_ADDRESS = "Enter Email Address";
export const VERIFICATION_EMAIL_RESEND_SUCCESS = "Successfully re-sent verification email";
