import type { ComponentType } from 'react';

import Grid from './grid';
import type { IColumnProps } from './grid.types';

const Column: ComponentType<IColumnProps> = props => {
  return <Grid {...props} orientation="y" />;
};

export default Column;
