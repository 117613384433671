import React from 'react'
import { IHeadingWithDescriptionProps } from './headingWithDescription.types'

const HeadingWithDescription: React.FC<IHeadingWithDescriptionProps> = ({heading, description}) => {
  return (
    <div>
        <h1 className='text-[#212121] text-2xl font-medium'>{heading}</h1>
        <p className='text-sm text-fontGrey1'>{description}</p>
    </div>
  )
}

export default HeadingWithDescription