import { Layout } from 'components'

export default function Home() {
  return (
    <Layout>
      <section className="flex items-center justify-center p-4 min-h-[calc(100vh-190px)]">
        <p>Home</p>
      </section>
    </Layout>
  )
}
