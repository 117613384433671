import { ClipboardIcon, Cog6ToothIcon, DocumentIcon, HomeIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { RouterUrls } from "../enums/routes.enum";
import {
  DocumentIcon as DocumentFillIcon,
  HomeIcon as HomeIconFilled,
  Cog6ToothIcon as Cog6ToothIconFilled,
  ClipboardIcon as ClipboardIconFilled,
  UserGroupIcon as UserGroupIconFilled
} from "@heroicons/react/20/solid";
import { isManageTeamEnabled } from "utils/common/common-utils";

const navigation = (permissions: string[] = []) => {
  const isEnabled = isManageTeamEnabled(permissions, RouterUrls.MANAGE_TEAM);
  return [
    { name: "Dashboard", to: RouterUrls.HOME, icon: HomeIcon, selectedIcon: HomeIconFilled, current: true, disabled: false },
    {
      name: "Manage Team",
      to: RouterUrls.MANAGE_TEAM,
      icon: UserGroupIcon,
      selectedIcon: UserGroupIconFilled,
      current: false,
      disabled: !isEnabled
    },
    {
      name: "Template Library",
      to: RouterUrls.TEMPLATE_LIBRARY,
      icon: DocumentIcon,
      selectedIcon: DocumentFillIcon,
      current: false,
      disabled: false
    },
    { name: "Settings", to: RouterUrls.SETTINGS, icon: Cog6ToothIcon, selectedIcon: Cog6ToothIconFilled, current: false, disabled: false },
    { name: "Atoms", to: RouterUrls.ATOMS, icon: ClipboardIcon, selectedIcon: ClipboardIconFilled, current: false, disabled: false }
  ];
};

const teams = [
  { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
  { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
  { id: 3, name: "Workcation", href: "#", initial: "W", current: false }
];

const TIME_SAVED = "Time Saved: 20 hours";
const GET_EVEN_MORE = "Get even more time savings";

interface OutletContextType {
  isManageTeamEnabled: boolean;
}

export { navigation, teams, TIME_SAVED, GET_EVEN_MORE };

export type { OutletContextType };
