import React, { useState } from "react";
import { Provider } from "react-redux";
import store from "../utils/redux/configure-store";
import AppRouter from "./app-router";
import { TawkChat } from "components";
import { Toaster } from "@/components/ui/toast/toaster";
import { ConfirmContext } from "components/confirmContext";
import AlertModal from "components/alertModal";
import { Button } from "atoms";
import { BtnText } from "constant/enums/btn-txt.enum";

const initialState = {
  show: false,
  isDirty: false,
  discardHandler: () => {
    console.log("");
  }
};

function App() {
  const [confirmDialog, setConfirmDialog] = useState({ ...initialState });

  const discardHandler = () => {
    if (confirmDialog.discardHandler) {
      confirmDialog.discardHandler();
    }
    setConfirmDialog({ ...initialState });
  };

  const closeHandler = () => {
    setConfirmDialog((prev) => ({ ...prev, show: false }));
  };

  return (
    <>
      <ConfirmContext.Provider
        value={{
          isDirty: confirmDialog.isDirty,
          showDialog: confirmDialog.show,
          setDiscardHandler: (handler) =>
            setConfirmDialog((prev) => ({
              ...prev,
              show: true,
              discardHandler: handler
            })),
          updateFormDirty: (isDirty) => setConfirmDialog((prev) => ({ ...prev, isDirty: isDirty }))
        }}
      >
        <Provider store={store}>
          <AppRouter />
          <TawkChat />
          <Toaster />
          <AlertModal
            title="Discard"
            variant="error"
            open={confirmDialog.isDirty && confirmDialog.show}
            onClose={closeHandler}
            footerButton={
              <>
                <Button variant="danger" customClasses="sm:ml-3 w-full sm:w-auto" onClick={discardHandler}>
                  {BtnText.DISCARD}
                </Button>
                <Button variant="secondary" customClasses="mt-3 sm:mt-0 w-full sm:w-auto" onClick={closeHandler}>
                  {BtnText.KEEP}
                </Button>
              </>
            }
          >
            <p>Are you sure you want to discard the changes you made?</p>
          </AlertModal>
        </Provider>
      </ConfirmContext.Provider>
    </>
  );
}

export default App;
