import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useTimer from "utils/hooks/useTimer";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
  CONTACT_US,
  RESEND,
  VERIFICATION_EMAIL_FAILIURE,
  VERIFICATION_EMAIL_RESEND_SUCCESS,
  WAITING_HEADER,
  WAITING_HELPER,
  WAITING_HELPER_2,
  WAITING_HELPER_FOOTER
} from "constant/texts/login";
import { BtnText } from "constant/enums/btn-txt.enum";
import { axiosInstance } from "utils/axios/axios-instance.utils";
import { WebApiEndPointUrls } from "constant/enums/webapi-endpoint.enum";
import { useToast } from "@/components/ui/toast/use-toast";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { RouterUrls } from "constant/enums/routes.enum";
import { CONTACT_US_MAIL } from "utils/common/common-utils";

const TIMER_DURATION = 30;

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const emailId = searchParams.get("email");
  const mode = searchParams.get("mode");
  const { timeRemaining, stop, reset } = useTimer(TIMER_DURATION);
  const { toast } = useToast();

  const resendText = timeRemaining > 0 ? `${"Resend in"} : ${timeRemaining} sec` : RESEND;

  useEffect(() => {
    if (timeRemaining <= 0) {
      stop();
    }
  }, [stop, timeRemaining]);

  const onResetClicked = async () => {
    if (timeRemaining <= 0) {
      try {
        const res = await axiosInstance.post(WebApiEndPointUrls.PASSWORDLESS_AUTH, {
          auth: mode,
          email: emailId
        });
        if (res?.data?.data) {
          toast({
            description: VERIFICATION_EMAIL_RESEND_SUCCESS,
            icon: <CheckCircleIcon className="h-6 w-6 text-[#59B07A]" />,
            className: "bg-[#ffffff]"
          });
          reset();
        }
      } catch (err) {
        toast({
          description: VERIFICATION_EMAIL_FAILIURE,
          icon: <XCircleIcon className="h-8 w-8 text-[#D86161]" />,
          className: "bg-[#ffffff]"
        });
      }
    }
  };

  const backBtnClick = () => {
    navigate(`${mode == "login" ? RouterUrls.LOGIN : RouterUrls.SIGN_UP}`, {
      state: {
        email: emailId
      },
      replace: true
    });
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="w-full md:w-[35rem] ml-4 md:m-*:auto mb-6">
          <button className="flex mr-auto" onClick={backBtnClick}>
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            <span className="font-medium text-base">{BtnText.GO_BACK}</span>
          </button>
        </div>
        <div className="md:border-solid md:border md:border-grey w-full md:w-[35rem] m-*:auto rounded-3xl">
          <div className="flex min-h-full flex-col justify-center px-8 py-8 lg:px-8 items-center gap-6">
            <div className="flex min-h-full flex-col justify-center items-center gap-8 ">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <h1 className="font-medium text-2xl md:text-4xl leading-9 text-[#121212]">{WAITING_HEADER}</h1>
              </div>
              <div>
                <img className="mx-auto w-38 h-[111px]" src="/email-inbox.png" alt="email-inbox" />
              </div>
              <div className="flex min-h-full flex-col justify-center items-center gap-2">
                <h2 className="font-normal text-base md:text-2xl text-[#121212]">{WAITING_HELPER}</h2>
                <h2 className="font-normal text-xl md:text-2xl text-[#4A4C52]">{emailId}</h2>
              </div>
              <div className="w-42 h-10">
                <button
                  className="flex w-full h-full items-center justify-center rounded-[5px]  px-2 py-4 border-[1px] border-[solid] border-[#66C98B] text-[#66C98B] font-medium text-base"
                  onClick={onResetClicked}
                >
                  {resendText}
                </button>
              </div>
            </div>
            <div className="text-center text-sm	font-normal	w-full">
              <span>{WAITING_HELPER_2}</span>
            </div>
            <div>
              <p className="text-center text-sm font-medium">
                {WAITING_HELPER_FOOTER}
                <a href={`mailto:${CONTACT_US_MAIL}`} className="text-sm font-medium text-[#59B07A] ml-1">
                  {CONTACT_US}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
