import React from 'react'
import parse from 'html-react-parser';

const HtmlRenderer = ({htmlContent}) => {

    const reactElement = parse(htmlContent);

  return (
    <div className='w-full min-h-24 text-[14px] leading-[18px] text-[#4A4C52] px-7 text-sm prose prose-a:text-teal-600 max-w-full'>
        {reactElement}
    </div>
  )
}

export default HtmlRenderer