import React from 'react'
import SingleProgressText from './singleProgressText'

const InitialLoading = () => {

    const progressText = [
        "Successfully integrated ATS....",
        "Loading jobs...",
        "Initializing neural network AI....",
        "Understanding candidate feedback....",
        "Personalizing candidate feedback...."
    ]

  return (
    <div>
        {progressText.map((text, index) => (
            <SingleProgressText text={text} numberOfProgressText={progressText.length} indexOfProgressText={index} />
        ))}
    </div>
  )
}

export default InitialLoading