import { ComponentType } from "react";
import { IInputProps } from "./input.types";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

const getInputClasses = (error, disabled) => {
  if (error) {
    return "px-3 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500";
  }
  if (disabled) {
    return "px-3 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-fontDark bg-primaryDisable";
  }
  return "px-3 text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-fontDark";
};

const Input: ComponentType<IInputProps> = ({ label, error = "", placeholder = "", name = "", ...props }) => {
  const inputClasses = `block w-full rounded-md border-0 py-1.5 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 ${getInputClasses(error, props.disabled)}`;

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input name={name} className={inputClasses} {...props} placeholder={placeholder} />
        {error ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>
      {error ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default Input;
