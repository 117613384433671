import React from 'react'
import { IRadioButtonProps } from './radioButton.types';

const RadioButton: React.FC<IRadioButtonProps> = ({ id, name, value, isChecked, onClick, label }) => {

  const handleClick = () => {
    onClick(value);
  }

    return (
      <div className='flex items-center'>
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={isChecked}
          onClick={handleClick}
          className='appearance-none rounded-full w-5 h-5 border-2 border-[#D8D8D8] checked:border-[7px] checked:border-[#0081CC] checked:bg-white'
        />
        <label htmlFor={id} className='text-sm text-[#7A7A7A] pl-1'>{label}</label>
      </div>
    );
  };

export default RadioButton