import { useCookies } from "react-cookie";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isManageTeamEnabled } from "utils/common/common-utils";

const ProtectedRoutes = () => {
  const [cookies] = useCookies();
  const location = useLocation();
  const isMTEnabled = isManageTeamEnabled(cookies["permission"], location.pathname);

  return cookies?.accessToken ? <Outlet context={{ isManageTeamEnabled: isMTEnabled }} /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
