import React, { useEffect, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { ISingleProgressTextProps } from './initialLoading.types';

const SingleProgressText: React.FC<ISingleProgressTextProps> = ({ text, numberOfProgressText, indexOfProgressText }) => {

    const [isTextActive, setIsTextActive] = useState<boolean>(false);

    useEffect(() => {

        const handleChangeToActive = () => {
            setTimeout(() => {
                setIsTextActive(true)
            }, (indexOfProgressText + 1) * 1000 )
        }

        handleChangeToActive()

        setInterval(() => {
            setIsTextActive(false);
            handleChangeToActive()
        }, (numberOfProgressText + 1 ) * 1000)

    },[])

  return (
    <div className={`${isTextActive? "text-fontDark" : "text-[#121212B2]"} flex items-center py-1`}>
        <CheckCircleIcon className='h-5 w-5 stroke-2'/>
        <div className='text-[16px] pl-4 font-medium'>{text}</div>
    </div>
  )
}

export default SingleProgressText