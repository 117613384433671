import { useContext } from "react";
import { ConfirmContext } from "./confirmContext";

const EventConfirmation = ({ clickHandler, children, className }) => {
  const { setDiscardHandler, isDirty } = useContext(ConfirmContext);

  const handleClick = () => {
    if (!isDirty) {
      clickHandler();
    } else {
      setDiscardHandler(clickHandler);
    }
  };

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  );
};

export default EventConfirmation;
