import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddlware from "redux-saga";
import rootReducer from "../../store/reducers/root.reducer";
import { rootSaga } from "../../store/sagas/root-saga";

const sagaMiddleware = createSagaMiddlware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (gDM) => gDM().concat(sagaMiddleware)
});

sagaMiddleware.run(rootSaga);

export default store;
