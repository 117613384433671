import { GET_EVEN_MORE, TIME_SAVED, UPGRADE } from "constant/texts";
import { Button, Column } from "atoms";

const UpgradeCard = () => {
  return (
    <div className="py-4 border-t border-b border-borderPrimary">
      <Column className="bg-greyMain rounded-[8px] p-4 justify-center gap-3">
        <div className="mb-3">
          <p className="text-fontWhite font-medium text-base">{TIME_SAVED}</p>
          <p className="text-fontGrey1 font-normal text-sm">{GET_EVEN_MORE}</p>
        </div>
        <Button>{UPGRADE}</Button>
      </Column>
    </div>
  );
};

export default UpgradeCard;
