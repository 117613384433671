import React, { useContext, useEffect } from "react";
import AlertModal from "./alertModal";
import { Button } from "atoms";
import { BtnText } from "constant/enums/btn-txt.enum";
import { BlockerFunction, useBlocker, Blocker } from "react-router";
import { ConfirmContext } from "./confirmContext";

const Confirm = ({ children, isDirty = false, isCancelled = false, resetHandler, cancelHandler }) => {
  const { updateFormDirty } = useContext(ConfirmContext);

  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
    [isDirty, isCancelled]
  );

  const blocker = useBlocker(shouldBlock);

  /**
   * Custom pop ups are not supported as security risks during unload event
   *
   * Also custom messages are disabled by almost all the browsers.
   *
   */

  useEffect(() => {
    if (isDirty) {
      updateFormDirty(true);
      const unloadEventHandler = (event) => {
        event?.preventDefault();
      };
      window.addEventListener("beforeunload", unloadEventHandler);

      return () => window.removeEventListener("beforeunload", unloadEventHandler);
    } else {
      updateFormDirty(false);
    }
  }, [isDirty]);

  React.useEffect(() => {
    if (blocker.state === "blocked" && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  return (
    <>
      {children}
      <ConfirmationDialog blocker={blocker} isCancelled={isCancelled} cancelHandler={cancelHandler} resetHandler={resetHandler} />
    </>
  );
};

const ConfirmationDialog = ({
  blocker,
  isCancelled = false,
  resetHandler = () => {
    console.log("resetHandler");
  },
  cancelHandler = () => {
    console.log("resetHandler");
  }
}: {
  blocker: Blocker;
  isCancelled: boolean;
  cancelHandler;
  resetHandler;
}) => {
  const discardHandler = () => {
    if (isCancelled) {
      typeof resetHandler === "function" && resetHandler();
    } else if (blocker.state === "blocked") {
      blocker?.proceed?.();
    }
  };

  const keepHandler = () => {
    if (isCancelled) {
      typeof cancelHandler === "function" && cancelHandler();
    } else if (blocker.state === "blocked") {
      blocker?.reset?.();
    }
  };

  return (
    <AlertModal
      title="Discard"
      variant="error"
      open={blocker.state === "blocked" || isCancelled}
      onClose={() => blocker?.reset?.()}
      footerButton={
        <>
          <Button variant="danger" customClasses="sm:ml-3 w-full sm:w-auto" onClick={discardHandler}>
            {BtnText.DISCARD}
          </Button>
          <Button variant="secondary" customClasses="mt-3 sm:mt-0 w-full sm:w-auto" onClick={keepHandler}>
            {BtnText.KEEP}
          </Button>
        </>
      }
    >
      <p>Are you sure you want to discard the changes you made?</p>
    </AlertModal>
  );
};

export default Confirm;
