import { useToast } from '@/components/ui/toast/use-toast'

const Toast = ({variant, buttonText, toastText, toastTitle}) => {

    const { toast } = useToast()
  return (
    <div>
        <button 
            onClick={() => {
                toast({
                  title: toastTitle,
                  description: toastText,
                  variant: variant,
                  className: "bg-[#ffffff]"
                })
              }}
        >
            {buttonText}
        </button>
    </div>
  )
}

export default Toast