import { Layout } from "components";
import ManageUserData from "./components/manage-user-data";
import ManageUserPermissions from "./components/manage-user-permissions";
import { useCookies } from "react-cookie";

const Settings = () => {
  const [cookies] = useCookies();

  console.log("cookies", cookies);

  return (
    <>
      <Layout>
        <ManageUserData />
        {/* Super admin can only access permissions */}
        {cookies && cookies.role === "OWNER" && <ManageUserPermissions />}
      </Layout>
    </>
  );
};

export default Settings;
