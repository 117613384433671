import { Layout } from 'components'

const TemplateLibrary = () => {
  return (
    <Layout>
      <section className='flex items-center justify-center p-4'>
        <p>Template Library</p>
      </section>
    </Layout>
  )
}

export default TemplateLibrary
