import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IModalProps, IModalSizeOptions } from "./modal.types";

const Modal: React.FC<IModalProps> = ({ title, children, footer, size, open, onClose, disableBackdrop = false }) => {

  const getModalSize = (size: IModalSizeOptions) => {
    switch (size) {
      case "small":
        return "sm:max-w-[500px] sm:p-6"
      case "medium":
        return "sm:max-w-[600px] sm:p-6"
      case "large":
        return "sm:max-w-[640px] sm:p-8"
      default: // extra large
        return "sm:max-w-[700px] sm:p-6";
    }
  };

  const getModalTitleFontSize = (size: IModalSizeOptions) => {
    switch (size) {
      case "small":
        return "text-lg"
      case "medium":
        return "text-lg"
      case "large":
        return "text-xl"
      default: // extra large
        return "text-xl";
    }
  };

  const closeHandler = () => {
    if(disableBackdrop)
        return;
    else  
      onClose()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative" onClose={closeHandler}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 z-[200] bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[200] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-sm transition-all border border-slate-200 sm:my-8 sm:w-full ${getModalSize(size)}`}>

                {/* Header */}
                <div className="flex justify-between border-b border-b-borderSecondary pb-3 mb-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                        as="h3"
                        className={`${getModalTitleFontSize(size)} font-medium leading-6 text-gray-900`}
                    >
                        {title}
                    </Dialog.Title>
                    <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={onClose}
                    >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6 stroke-2" aria-hidden="true" />
                    </button>
                </div>

                {/* Children Component */}
                <div>
                    {children}
                </div>

                {/* Footer Component */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {footer}
                </div>

              </Dialog.Panel>

            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal