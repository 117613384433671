import React, { useState } from 'react'
import { classNames } from 'utils/string';
import { ITooltipWrapperProps } from './tooltip.types';

const TooltipWrapper: React.FC<ITooltipWrapperProps> = ({children, tooltipContent, variant}) => {

  const [showInfo, setShowInfo] = useState(false);


  return (
    <div>
        <a
            onMouseEnter={() => setShowInfo(true)}
            onMouseLeave={() => setShowInfo(false)}
            className='inline-block'
            href='#'
        >
                {children}

                {showInfo && <span
                    className={"absolute z-[70]"}
                    >
                    <span
                        className={classNames("relative -top-[10px] -z-10 -translate-x-1/2 w-0 h-0 border-l-[10px] border-l-transparent border-b-[10px] border-b-gray-200 border-r-[10px] border-r-transparent",  variant == "dark"? "border-b-gray-50" : "border-b-zinc-800")}
                    ></span>
                    <span className={classNames(`block -top-1 relative right-1/2 w-56 sm:w-96 p-4 rounded-[4px] text-xs sm:text-sm text-wrap be`, variant == "dark"? "bg-gray-50 text-slate-900" : "bg-zinc-800 text-white")}>
                        {tooltipContent}
                    </span>
                </span>}
        </a>
        
    </div>
  )
}

export default TooltipWrapper