import { Button } from "atoms";
import { useNavigate } from "react-router";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import { BtnText } from "constant/enums/btn-txt.enum";
import { fetchErrorCode } from "utils/common/common-utils";
import { useSearchParams } from "react-router-dom";

const ExpiryLinkPage = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const { title, description } = fetchErrorCode(params.get("status_code"));

  return (
    <main className="min-h-screen flex justify-center items-center bg-white px-6 lg:px-8">
      <div className="text-center">
        <div className="flex justify-center">
          <Cog8ToothIcon className="h-8 w-8 text-[#9CA3AF]" />
        </div>
        <h1 className="mt-5 text-sm font-medium tracking-tight text-greyMain sm:text-base">{title}</h1>
        <p className="mt-3 text-xs font-normal leading-7 text-fontGrey1 sm:text-sm">{description}</p>
        <div className="mt-4 flex items-center justify-center gap-x-6">
          <Button onClick={() => navigate("/")}>{BtnText.CONTINUE_TO_ACCOUNT}</Button>
        </div>
      </div>
    </main>
  );
};

export default ExpiryLinkPage;
