import { useToast } from "@/components/ui/toast/use-toast";
import { PencilIcon, TrashIcon, EnvelopeIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { Button, Input, Select, Pill } from "atoms";
import ThreeDotMenu from "atoms/three-dot-menu";
import { IOptionItem } from "atoms/three-dot-menu/threeDotMenu.types";
import { AxiosError } from "axios";
import { Layout } from "components";
import HeadingWithDescription from "components/headingWithDescription";
import Modal from "components/modal";
import Table from "components/table";
import UserDisplay from "components/user-display";
import { BtnText } from "constant/enums/btn-txt.enum";
import { WebApiEndPointUrls } from "constant/enums/webapi-endpoint.enum";
import { ChangeEvent, useEffect, useState } from "react";
import { axiosInstance } from "utils/axios/axios-instance.utils";
import { isNotEmpty, validateEmail } from "utils/common/common-utils";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useOutletContext } from "react-router";
import { OutletContextType } from "constant/texts";

interface IRoleProps {
  id: number;
  label: string;
  description: string;
  name: string;
}

const ManageTeam = () => {
  const context: OutletContextType = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!context?.isManageTeamEnabled) {
      navigate("/");
    }
  }, [context]);

  const addTeamMember = () => {
    setShowTeamMemberDialog({
      show: true,
      isEdit: false,
      userData: undefined
    });
  };

  const editTeamMember = (row) => {
    setShowTeamMemberDialog({
      show: true,
      isEdit: true,
      userData: row
    });
  };

  const columns = [
    {
      name: "TEAM MEMBER",
      selector: (row) => row.name,
      grow: 2
    },
    {
      name: "STATUS",
      selector: (row) => row.status
    },
    {
      name: "USER ROLE",
      selector: (row) => row.roleDisplayName
    },
    {
      name: "PERMISSION",
      selector: (row) => row.permissions,
      grow: 2
    },
    {
      name: "ACTION",
      selector: (row) => row.actionAllowed && <ThreeDotMenu data={row} options={menuOptions(row.statusText)} variant="vertical" />
    }
  ];

  const menuOptions = (statusText): IOptionItem[] => {
    const options = [...baseMenuOptions];
    if (statusText === "ACCEPTED") options.push(...acceptedMenuOptions);
    if (statusText === "INVITED") options.push(...invitedMenuOptions);
    return options;
  };

  const { toast } = useToast();

  const resendInviteHandler = async (row) => {
    try {
      const res = await axiosInstance.post(
        `${WebApiEndPointUrls.EDIT_MEMBER}/${row.userId}/resend-invite`,
        {},
        {
          withCredentials: true
        }
      );
      if (res.status == 200) {
        toast({
          description: res.data.message ? res.data.message : "Invite sent successfully.",
          variant: "success"
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      let message = "Something went wrong.";
      try {
        message = ((error as AxiosError).response?.data as { code: string; message: string }).message;
      } catch (error) {
        console.error(error);
      }
      toast({
        description: message,
        variant: "error"
      });
    }
  };

  const deleteMemberHandler = async (row) => {
    try {
      const res = await axiosInstance.delete(`${WebApiEndPointUrls.EDIT_MEMBER}/${row.userId}`, {
        withCredentials: true
      });
      if (res.status == 200) {
        toast({
          description: res.data.message ? res.data.message : "Member deleted successfully.",
          variant: "success"
        });
        setPageRefresh((prev) => prev + 1);
      } else {
        throw new Error();
      }
    } catch (error) {
      let message = "Something went wrong.";
      try {
        message = ((error as AxiosError).response?.data as { code: string; message: string }).message;
      } catch (error) {
        console.error(error);
      }
      toast({
        description: message,
        variant: "error"
      });
    }
    closeResendRevokeDialog();
  };

  const revokeInviteHandler = async (row) => {
    try {
      const res = await axiosInstance.delete(`${WebApiEndPointUrls.EDIT_MEMBER}/${row.userId}`, {
        withCredentials: true
      });
      if (res.status == 200) {
        toast({
          description: res.data.message ? res.data.message : "Invite revoked successfully.",
          variant: "success"
        });
        setPageRefresh((prev) => prev + 1);
      } else {
        throw new Error();
      }
    } catch (error) {
      let message = "Something went wrong.";
      try {
        message = ((error as AxiosError).response?.data as { code: string; message: string }).message;
      } catch (error) {
        console.error(error);
      }
      toast({
        description: message,
        variant: "error"
      });
    }
    closeResendRevokeDialog();
  };

  const baseMenuOptions: IOptionItem[] = [
    {
      icon: <PencilIcon className="h-6 w-6 text-[#7A7A7A]" />,
      optionText: "Edit permission",
      handleClick: editTeamMember
    }
  ];

  const invitedMenuOptions: IOptionItem[] = [
    {
      icon: <EnvelopeIcon className="h-6 w-6 text-[#7A7A7A]" />,
      optionText: "Resend invite",
      handleClick: resendInviteHandler
    },
    {
      icon: <NoSymbolIcon className="h-6 w-6 text-[#7A7A7A]" />,
      optionText: "Revoke invite",
      handleClick: (row) =>
        setResendRevokeConfirmDialog({
          show: true,
          dialog: "revoke",
          data: row
        })
    }
  ];

  const acceptedMenuOptions: IOptionItem[] = [
    {
      icon: <TrashIcon className="h-6 w-6 text-[#7A7A7A]" />,
      optionText: "Remove member",
      handleClick: (row) =>
        setResendRevokeConfirmDialog({
          show: true,
          dialog: "remove",
          data: row
        })
    }
  ];

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchTableData = async (page, rowsPerPage) => {
    fetchMembers(page, rowsPerPage);
  };

  const [showTeamMemberDialog, setShowTeamMemberDialog] = useState({
    show: false,
    isEdit: false,
    userData: undefined
  });

  useEffect(() => {
    if (context?.isManageTeamEnabled) {
      fetchRoles();
      fetchMembers(1, rowsPerPage);
    }
  }, [context]);

  const [resendRevokeConfirmDialog, setResendRevokeConfirmDialog] = useState({
    show: false,
    dialog: "", // remove / revoke
    data: undefined // member row
  });

  const closeResendRevokeDialog = () => {
    setResendRevokeConfirmDialog({
      show: false,
      dialog: "",
      data: undefined
    });
  };

  const [totalRows, setTotalRows] = useState(0);

  const [refresh, setPageRefresh] = useState(-1);

  const [roles, setRoles] = useState<IRoleProps[] | []>([]);

  useEffect(() => {
    if (refresh >= 0) fetchMembers(1, rowsPerPage);
  }, [refresh]);

  const fetchMembers = async (pageNo, rowsPerPage) => {
    setLoading(true);
    try {
      const res = await axiosInstance.get(WebApiEndPointUrls.TEAM + "?pageNo=" + pageNo + "&pageSize=" + rowsPerPage);
      if (res.data?.data) {
        const members = res.data.data;
        const formattedMemebers = members.map((data) => ({
          userId: data.userId,
          name: (
            <UserDisplay
              userName={data.name?.toLowerCase()}
              userEmail={data.email}
              customClasses={data.role === "OWNER" ? "" : "max-w-[12rem] 2xl:max-w-none"}
            />
          ),
          status: data.status && (
            <Pill
              variant={data.status === "INVITED" ? data.invitationExpired ? "gray" : "blue" : data.status === "ACCEPTED" ? "green" : "yellow"}
              style={{ textTransform: "capitalize" }}
            >
              {data.invitationExpired ? "Invitation expired" : data.status.toLowerCase()}
            </Pill>
          ),
          role: data.role,
          roleDisplayName: data.roleDisplayName,
          permissions: <span className="capitalize">{data.permissions} </span>,
          email: data.email,
          sortableName: data.name,
          statusText: data.status,
          actionAllowed: data.actionAllowed
        }));
        setTableData(formattedMemebers);
        setTotalRows(res.data.total);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchRoles = async () => {
    try {
      const res = await axiosInstance.get(WebApiEndPointUrls.ROLES);
      if (res.data?.data) {
        const formattedRoles = Object.keys(res.data.data).map((_, key) => ({
          id: key,
          label: res.data?.data[_].displayName,
          description: res.data?.data[_].tag,
          name: _
        }));
        setRoles(formattedRoles);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeTeamMemberHandler = (refreshRequired) => {
    setShowTeamMemberDialog((prev) => ({
      ...prev,
      show: false,
      userData: undefined
    }));
    if (refreshRequired) setPageRefresh((prev) => prev + 1);
  };

  return (
    context?.isManageTeamEnabled && (
      <>
        <Layout>
          <section className="flex flex-col m-t-overflow">
            <div className="flex flex-col sm:flex-row gap-4 justify-between items-start sm:items-center mb-12">
              <HeadingWithDescription
                heading="Manage team"
                description="This is where the existing and pending members will show. You can set the permissions for each user role in the Settings tab."
              />
              <Button customClasses="text-nowrap" onClick={addTeamMember}>
                {BtnText.ADD_TEAM_MEMBER}
              </Button>
            </div>
            <div className="table-overflow">
              <Table
                columns={columns}
                data={tableData}
                fetchData={fetchTableData}
                totalRows={totalRows}
                loading={loading}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
              />
            </div>
          </section>
          <AddUpdateTeamMember
            showTeamMemberDialog={showTeamMemberDialog.show}
            isEdit={showTeamMemberDialog.isEdit}
            closeTeamMemberHandler={closeTeamMemberHandler}
            roles={roles}
            userData={showTeamMemberDialog.userData}
          />
          <Modal
            title={resendRevokeConfirmDialog.dialog === "remove" ? "Remove member" : "Revoke invite"}
            disableBackdrop={true}
            size="small"
            open={resendRevokeConfirmDialog.show}
            onClose={closeResendRevokeDialog}
            children={
              <div className="flex flex-col gap-4 items-center text-center py-4">
                <div className="text-base font-medium">
                  {resendRevokeConfirmDialog.dialog === "remove"
                    ? "Are you sure you want to remove the team member?"
                    : "Are you sure you want to revoke the invite?"}
                </div>
                <div className="flex gap-1 bg-[#D861611A] py-4 px-12 text-left text-base text-[#D86161] font-medium rounded-[10px]">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  <span>This action cannot be undone</span>
                </div>
                <div className="text-sm/4 text-[#4D4D4D]">
                  This action can not be undone, you have to re-invite the team member if they need access.
                </div>
              </div>
            }
            footer={
              <>
                <Button
                  variant="danger"
                  onClick={
                    resendRevokeConfirmDialog.dialog === "remove"
                      ? () => deleteMemberHandler(resendRevokeConfirmDialog.data)
                      : () => revokeInviteHandler(resendRevokeConfirmDialog.data)
                  }
                >
                  {resendRevokeConfirmDialog.dialog === "remove" ? "Remove" : "Revoke"}
                </Button>
                <Button variant="tertiary" onClick={closeResendRevokeDialog}>
                  {BtnText.CANCEL}
                </Button>
              </>
            }
          />
        </Layout>
      </>
    )
  );
};

const AddUpdateTeamMember = ({ showTeamMemberDialog, closeTeamMemberHandler, isEdit = false, roles, userData }) => {
  const [teamMemberForm, setTeamMemberForm] = useState({
    email: "",
    role: ""
  });

  useEffect(() => {
    if (userData) {
      setTeamMemberForm({
        email: userData.email,
        role: userData.role
      });
    } else {
      setTeamMemberForm({
        email: "",
        role: ""
      });
    }
  }, [userData, showTeamMemberDialog]);

  const [showDiscardPopup, setShowDiscardPopup] = useState(false);

  const selectRole = (role: IRoleProps) => {
    setTeamMemberForm((prev) => ({ ...prev, role: role.name }));
  };

  useEffect(() => {
    if (userData) {
      setIsDetailsEdited(teamMemberForm.role !== userData.role);
    } else {
      setIsDetailsEdited(teamMemberForm.role !== "" && teamMemberForm.email !== "");
    }
  }, [teamMemberForm]);

  const cancelHandler = () => {
    if (isDetailsEdited) {
      setShowDiscardPopup(true);
    } else {
      closeTeamMemberHandler(false);
    }
  };

  const { toast } = useToast();

  const editHandler = async () => {
    let isRefreshRequired = false;
    try {
      const res = await axiosInstance.patch(
        `${WebApiEndPointUrls.EDIT_MEMBER}/${userData.userId}`,
        {
          role: teamMemberForm.role
        },
        {
          withCredentials: true
        }
      );
      if (res.status == 200) {
        isRefreshRequired = true;
        toast({
          description: res.data.message ? res.data.message : "Permissions updated successfully.",
          variant: "success"
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      let message = "Something went wrong.";
      try {
        message = ((error as AxiosError).response?.data as { code: string; message: string }).message;
      } catch (error) {
        console.error(error);
      }
      toast({
        description: message,
        variant: "error"
      });
    }
    setShowConfirmDialog(false);
    closeTeamMemberHandler(isRefreshRequired);
  };

  const saveHandler = async () => {
    let isRefreshRequired = false;
    if (isEnabled()) {
      if (isEdit && userData) {
        setShowConfirmDialog(true);
      } else {
        try {
          const res = await axiosInstance.post(
            `${WebApiEndPointUrls.MEMBER}`,
            {
              email: teamMemberForm.email,
              role: teamMemberForm.role
            },
            {
              withCredentials: true
            }
          );
          if (res.status == 200) {
            isRefreshRequired = true;
            toast({
              description: res.data.message ? res.data.message : "User invited successfully.",
              variant: "success"
            });
          }
        } catch (error) {
          let message = "Something went wrong.";
          try {
            message = ((error as AxiosError).response?.data as { code: string; message: string }).message;
          } catch (error) {
            console.error(error);
          }
          toast({
            description: message,
            variant: "error"
          });
        }
        closeTeamMemberHandler(isRefreshRequired);
      }
    }
  };

  const [isDetailsEdited, setIsDetailsEdited] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDetailsEdited(true);
    setTeamMemberForm((prev) => ({ ...prev, email: event.target.value }));
  };

  const isEnabled = () => {
    if (isEdit) return isDetailsEdited;
    else return validateEmail(teamMemberForm.email) && isNotEmpty(teamMemberForm.role);
  };

  const discardHandler = () => {
    // reset the form
    setTeamMemberForm({
      email: userData ? userData.email : "",
      role: userData ? userData.role : ""
    });
    setShowDiscardPopup(false);
  };

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  return (
    <>
      <Modal
        disableBackdrop={true}
        title={isEdit ? "Update permission" : "Add team member"}
        size="large"
        open={showTeamMemberDialog}
        onClose={cancelHandler}
        children={
          <div className="flex flex-col gap-2 items-start text-left md:py-4 md:px-12">
            <div className="text-base font-medium">Please enter the team member's email address.</div>
            <div className="text-sm/4 text-[#4D4D4D]">
              Based on the permissions in the settings page, members will have the ability to view, edit and send feedback, and invite/manage the
              team.
            </div>
            <div className="flex flex-col gap-4 mt-4 w-full">
              <Input
                label={"Email address"}
                error={""}
                name={"email"}
                disabled={isEdit}
                type="email"
                placeholder={"ex. someone@domain.com"}
                value={teamMemberForm.email}
                onChange={handleEmailChange}
              />
              <Select<IRoleProps>
                label="Role"
                placeholder="Please select"
                options={roles}
                selected={roles.find((role) => role.name == teamMemberForm.role) ?? ({} as IRoleProps)}
                onChange={selectRole}
                showTooltip={true}
                toolTipChildren={
                  <div className="flex flex-col gap-2">
                    <div className="text-base bold">Roles and Permissions</div>
                    <div className="flex flex-col gap-1">
                      {roles.map((_) => (
                        <div className="">
                          <span>{_.label}</span> : <span> {_.description}</span>{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                }
              />
            </div>
            {roles.length > 0 && <div className=" text-sm text-gray-500">{roles.find((role) => role.name == teamMemberForm.role)?.description}</div>}
          </div>
        }
        footer={
          <>
            <Button variant="primary" customClasses="ml-2" onClick={saveHandler} disabled={!isEnabled()}>
              {isEdit ? BtnText.SAVE : BtnText.ADD_MEMBER}
            </Button>
            <Button variant="outline" style={{ color: "#4A4C52" }} customClasses="!ring-[#4A4C52]" onClick={cancelHandler}>
              {BtnText.CANCEL}
            </Button>
          </>
        }
      />
      <Modal
        title="Discard"
        size="medium"
        open={showDiscardPopup}
        disableBackdrop={true}
        onClose={() => setShowDiscardPopup(false)}
        footer={
          <>
            <Button variant="danger" customClasses="sm:ml-3 w-full sm:w-auto" onClick={discardHandler}>
              {BtnText.DISCARD}
            </Button>
            <Button variant="secondary" customClasses="mt-3 sm:mt-0 w-full sm:w-auto" onClick={() => setShowDiscardPopup(false)}>
              {BtnText.KEEP}
            </Button>
          </>
        }
      >
        <p>Are you sure you want to discard the changes you made?</p>
      </Modal>
      <Modal
        title="Permission Change"
        disableBackdrop={true}
        size="small"
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        children={
          <div className="flex flex-col gap-4 items-center text-center py-4">
            <div className="text-base font-medium">Are you sure you want to update the permission?</div>
            <div className="flex gap-1 bg-[#D861611A] py-4 px-12 text-left text-base text-[#D86161] font-medium rounded-[10px]">
              <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
              <span>Please make sure they should have access to the information.</span>
            </div>
            <div className="text-sm/4 text-[#4D4D4D]">
              This action can be undone. You have to reset it by clicking edit permission in the Settings tab.
            </div>
          </div>
        }
        footer={
          <>
            <Button variant="danger" onClick={editHandler}>
              {BtnText.UPDATE}
            </Button>
            <Button variant="tertiary" onClick={() => setShowConfirmDialog(false)}>
              {BtnText.CANCEL}
            </Button>
          </>
        }
      />
    </>
  );
};

export default ManageTeam;
