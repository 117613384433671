import { useCookies } from "react-cookie";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isManageTeamEnabled } from "utils/common/common-utils";

const OnboardingProtectedRoutes = () => {
  const [cookies] = useCookies();
  const location = useLocation();

  const isMTEnabled = isManageTeamEnabled(cookies["permission"], location.pathname);

  return cookies?.accessToken && cookies.hasCompletedOnboarding ? (
    <Outlet context={{ isManageTeamEnabled: isMTEnabled }} />
  ) : (
    <Navigate to="/onboarding/step-1" />
  );
};

export default OnboardingProtectedRoutes;
