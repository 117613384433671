export enum BtnText {
  LOGIN = "Log in",
  SIGN_UP = "Sign up",
  GO_BACK = "Go Back",
  USER_ACCOUNT = "Create Account",
  LETS_DO = "Let’s do it!",
  CANCEL = "Cancel",
  SAVE = "Save",
  UPDATE = "Update",
  ADD_TEAM_MEMBER = "Add Team Member",
  ADD_MEMBER = "Add Member",
  DISCARD = "Discard",
  KEEP = "Keep",
  CONTINUE_TO_ACCOUNT = "Continue to account"
}
