import Avatar from 'atoms/avatar'
import React from 'react'
import { IUserDisplay } from './userDisplay.types'

const UserDisplay: React.FC<IUserDisplay> = ({ userName, userEmail, imageSrc ,customClasses }) => {
  return (
    <div className='flex py-4 pr-6'>
      <div className='flex-none'>
        <Avatar userName={userName} imageSrc={imageSrc} />
      </div>
      <div className={`pl-4 pr-4 flex flex-col flex-auto justify-center truncate ${customClasses}`}>
          <p className='text-sm text-gray-900 capitalize truncate'>{userName}</p>
          {userEmail && <p className='text-sm text-gray-500 truncate'>{userEmail}</p>}
      </div>
    </div>
  )
}

export default UserDisplay