import { APP_TAG_LINE, ONBOARDING_FEEDBACK } from "constant/texts/login";
import { useNavigate } from "react-router";
import { BtnText } from "constant/enums/btn-txt.enum";

/**
 * User will be navigated to this page once user's
 * onboarding completes
 *
 */

const Steps = [
  {
    key: 1,
    title: "Connect Your ATS",
    icon: "/assets/OF1.svg"
  },
  {
    key: 2,
    title: "Select a candidate from your job",
    icon: "/assets/OF2.svg"
  },
  {
    key: 3,
    title: "Review & send feedback",
    icon: "/assets/OF3.svg"
  }
];

const OnboardingStep2 = () => {
  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate("/");
  };

  return (
    <div className="flex justify-center flex-col items-center px-4 py-4 h-screen overflow-auto">
      <h2 className="text-center font-semibold sm:text-2xl md:text-4xl lg:text-5xl">{ONBOARDING_FEEDBACK}</h2>
      <h2 className="sm:mt-4 text-center text-[#7a7a7a] font-normal text-lg sm:text-2xl">{APP_TAG_LINE}</h2>
      <div className="mt-6 md:mt-16 flex flex-col sm:flex-row justify-center gap-8">
        {Steps.map((step) => (
          <div key={step.key} className="flex flex-col justify-center items-center flex-1 max-w-full lg:whitespace-nowrap">
            <div className="flex justify-center items-center w-[100px] h-[100px] md:w-[150px] md:h-[150px] lg:w-[200px] lg:h-[200px]">
              <img src={step.icon} className="object-contain" /> </div>
            <div className="mt-8 text-center font-semibold text-xl/5 sm:text-2xl/5">{step.title}</div>
          </div>
        ))}
      </div>
      <div className="mt-16">
        <button
          className="flex w-full items-center justify-center rounded-[5px] font-medium focus:outline 
          rounded-md bg-primary px-8 py-2 text-base/5 font-semibold text-fontDark shadow-sm hover:bg-primaryHover 
          focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
          onClick={redirectHandler}
        >
          {BtnText.LETS_DO}
        </button>
      </div>
    </div>
  );
};

export default OnboardingStep2;
