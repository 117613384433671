import { createContext } from "react";

export const ConfirmContext = createContext({
  isDirty: false,
  showDialog: false,
  updateFormDirty: (isDirty: boolean) => {
    console.log("Update form state", isDirty);
  },
  setDiscardHandler: (handler) => {
    console.log("Setter of discard handler", handler);
  }
});
