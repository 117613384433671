import React from "react";
import { ICheckboxProps } from "./checkbox.types";

const Checkbox: React.FC<ICheckboxProps> = ({ id, name, value, isChecked, onClick, label, disabled = false }) => {
  return (
    <span className="flex items-center justify-center">
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={isChecked}
        value={value}
        onClick={onClick}
        disabled={disabled}
        className={`w-[20px] h-[20px] bg-transparent border ${disabled ? "text-[#7FFBAE80] border-[#E6E6E6]" : "text-primary border-[#7A7A7A] cursor-pointer"} 
        rounded-[5px] focus:ring-0 focus:ring-offset-0`}
      />
      {label && (
        <label htmlFor={id} className="text-sm text-[#7A7A7A] pl-2">
          {label}
        </label>
      )}
    </span>
  );
};

export default Checkbox;
