import { PowerIcon, PowerIcon as PowerIconFilled } from "@heroicons/react/24/outline";
import UpgradeCard from "components/upgrade-card";
import { LOGOUT, navigation } from "constant/texts";
import { classNames } from "utils/string";
import Item from "./item";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { logoutHelper } from "utils/common/common-utils";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useCookies } from "react-cookie";
import EventConfirmation from "components/eventConfirmation";

const SidebarContent = () => {
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState("");
  const [cookies] = useCookies();

  const logoutHandler = () => {
    logoutHelper(<CheckCircleIcon className="h-6 w-6 text-[#59B07A]" />, <XCircleIcon className="h-8 w-8 text-[#D86161]" />);
  };

  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
      <div className="flex h-16 shrink-0 items-center">
        <img className="w-40 mt-4" src="/logo.png" alt="Your Company" />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation(cookies["permission"])
                .filter((item) => !item.disabled)
                .map((item) => (
                  <li key={item.name} onMouseEnter={() => setIsHovered(item.to)} onMouseLeave={() => setIsHovered("")}>
                    <Link
                      to={item.to}
                      className={classNames(
                        item.to === pathname ? "bg-gray-50" : "text-gray-700  hover:bg-gray-50 hover:text-fontDark",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark"
                      )}
                    >
                      {item.to === pathname || item.to === isHovered ? (
                        <item.selectedIcon className={`h-6 w-6 shrink-0 text-fontDark `} aria-hidden="true" />
                      ) : (
                        <item.icon
                          className={`h-6 w-6 shrink-0 ${item.to === pathname ? "text-indigo-600" : "text-gray-400 group-hover:text-indigo-600"} `}
                          aria-hidden="true"
                        />
                      )}
                      {item.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </li>

          <li className="mt-auto">
            {/* upgrade card */}
            <UpgradeCard />
            <EventConfirmation className="mt-3" clickHandler={logoutHandler}>
              <Item title={LOGOUT} icon={PowerIcon} selectedIcon={PowerIconFilled} isHovered={isHovered} setIsHovered={setIsHovered} />
            </EventConfirmation>
          </li>
        </ul>
      </nav>
    </div>
  );
};
export default SidebarContent;
