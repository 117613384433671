import { Fragment, useState } from "react";
import { ChevronDownIcon, MagnifyingGlassIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid";
import { Menu, Transition, Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { classNames } from "utils/string";
import { userNavigation } from "constant/texts";
import SidebarContent from "components/sidebar/sidebarContent";
import { logoutHelper } from "utils/common/common-utils";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useCookies } from "react-cookie";
import Avatar from "atoms/avatar";
import EventConfirmation from "components/eventConfirmation";

const Navbar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [cookies] = useCookies();

  const username = cookies["userName"];

  const itemClickHandler = (itemName: string) => {
    if (itemName === "Log out") {
      logoutHelper(<CheckCircleIcon className="h-6 w-6 text-[#59B07A]" />, <XCircleIcon className="h-8 w-8 text-[#D86161]" />);
    }
    return true;
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SidebarContent />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />
        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-fontDark" aria-hidden="true" />
            <input
              id="search-field"
              className="block h-full w-full border-0 py-0 pl-8 pr-0 text-fontDark placeholder:text-fontDark focus:ring-0 sm:text-sm"
              placeholder="Type to search for jobs or ID"
              type="search"
              name="search"
            />
          </form>
          <div className="flex items-center gap-x-4 lg:gap-x-6 ">
            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fontDark">
                <span className="sr-only">Open user menu</span>
                <Avatar userName={username} />
                <span className="hidden lg:flex lg:items-center">
                  <span
                    className="ml-4 text-sm font-semibold leading-6 text-fontDark truncate w-24 capitalize"
                    title={username ? username.toLowerCase() : ""}
                    aria-hidden="true"
                  >
                    {username ? username.toLowerCase() : ""}
                  </span>
                  <ChevronDownIcon className="ml-2 h-5 w-5 text-fontDark" aria-hidden="true" />
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-fontDark/5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) =>
                        item.href === "#" ? (
                          <EventConfirmation
                            clickHandler={() => itemClickHandler(item?.name)}
                            className={classNames(active ? "bg-gray-50" : "", "cursor-pointer block px-3 py-1 text-sm leading-6 text-fontDark")}
                          >
                            {item.name}
                          </EventConfirmation>
                        ) : (
                          <Link to={item.href} className={classNames(active ? "bg-gray-50" : "", "block px-3 py-1 text-sm leading-6 text-fontDark")}>
                            {item.name}
                          </Link>
                        )
                      }
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
