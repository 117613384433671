import React from "react";
import { IButtonProps, IButtonVariant } from "./button.types";

const getVariantClasses = (variant: IButtonVariant, disabled?: boolean): string => {
  if (disabled) {
    return "px-2.5 py-1.5 text-sm shadow-sm border-expired bg-primaryDisable text-fontGrey2 cursor-not-allowed";
  }

  switch (variant) {
    case "primary":
      return "rounded-md bg-primary px-2.5 py-1.5 text-sm text-fontDark shadow-sm hover:bg-primaryHover";
    case "danger":
      return "rounded-md bg-red-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-red-500";
    case "outline":
      return "rounded-md bg-white px-2.5 py-1.5 text-sm text-primaryDarker shadow-sm ring-1 ring-inset ring-primaryDarker hover:bg-primaryDarker/5";
    case "secondary":
      return "rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50";
    case "tertiary":
      return "rounded-md bg-white px-2.5 py-1.5 text-sm text-gray-900 hover:bg-gray-50";
    default:
      return "";
  }
};

const Button: React.FC<IButtonProps> = ({
  variant = "primary",
  disabled = false,
  icon,
  iconPosition = "left",
  children,
  customClasses = "",
  ...props
}) => {
  const variantClasses = getVariantClasses(variant, disabled);
  const buttonClasses = `inline-flex items-center justify-center rounded-[5px] font-medium ${variantClasses} ${customClasses}`;

  const iconSize = "w-5 h-5";

  const renderIcon = icon ? (
    <span className={`mr-2 ${iconPosition === "right" ? "ml-2 mr-0" : ""}`}>
      {React.cloneElement(icon as React.ReactElement, { className: iconSize })}
    </span>
  ) : null;

  return (
    <button className={buttonClasses} {...props} disabled={disabled}>
      {iconPosition === "left" && renderIcon}
      {children}
      {iconPosition === "right" && renderIcon}
    </button>
  );
};

export default Button;
