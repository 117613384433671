import React from 'react';
import type { ComponentType } from 'react';

import Grid from './grid';
import type { IRowProps } from './grid.types';

const Row: ComponentType<IRowProps> = props => {
  return <Grid {...props} orientation="x" />;
};

export default Row;
