import React, { useState } from 'react'
import SearchBarInput from './searchBarInput'
import SearchResultsList from './searchResultsList'
import { ISearchBarProps } from './searchBar.types'

const SearchBar: React.FC<ISearchBarProps> = ({getSearchResult, searchResult, handleOptionClick}) => {

  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [searchBarFocused, setSearchBarFocused] = useState<boolean>(false);
  const [isDropdownFocused, setIsDropdownFocused] = useState<boolean>(true);

  return (
    <div className='w-full relative' onFocus={() => setSearchBarFocused(true)} onBlur={() => setSearchBarFocused(false)} >
        <SearchBarInput getSearchResult={getSearchResult} searchResult={searchResult} activeIndex={activeIndex} setActiveIndex={setActiveIndex} handleOptionClick={handleOptionClick} />
        {(isDropdownFocused || searchBarFocused) && searchResult && searchResult.length > 0 && <SearchResultsList searchResult={searchResult} activeIndex={activeIndex} handleOptionClick={handleOptionClick} setIsDropdownFocused={setIsDropdownFocused} />}
    </div>
  )
}

export default SearchBar