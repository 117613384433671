import { Row } from 'atoms'

const Container = ({ children, title }) => {
  return (
    <>
      <section className='my-5'>
        <h5>{title}</h5>
        <Row className='mt-2' gap={10}>
          {children}
        </Row>
      </section>
      <hr />
    </>
  )
}

export default Container
