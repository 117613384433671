import React, { useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { ISearchBarInputProps } from './searchBar.types';


const SearchBarInput: React.FC<ISearchBarInputProps> = ({ getSearchResult, searchResult, activeIndex, setActiveIndex, handleOptionClick }) => {

    const [searchQuery, setSearchQuery] = useState("")

    let searchBarText: string;

    function debounce<T extends (...args: any[]) => void>(
        callbackFunction: T,
        delay = 500
    ): (...args: Parameters<T>) => void {
        let timeout: NodeJS.Timeout;
    
        return (...args: Parameters<T>) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                callbackFunction(...args);
            }, delay);
        };
    }

    const getSearchResultCall = debounce(() => getSearchResult(searchBarText))

    const handleChange = (event) => {
        searchBarText = event.target.value;
        setSearchQuery(searchBarText)
        getSearchResultCall();
    }

  const handleKeyUp = (event) => {

    const keyCode = event.keyCode;
    if (keyCode === 13) {
      // user enter
      
      if (activeIndex === null || activeIndex < 0 || activeIndex >= searchResult.length) return;
      
      setSearchQuery(searchResult[activeIndex]);
      setActiveIndex(null)
      handleOptionClick(activeIndex)
      return;
    }

    if (!searchResult || searchResult.length === 0) return;

    if (keyCode === 40) {
      // move down
      if (activeIndex === null || activeIndex === searchResult.length - 1) setActiveIndex(0);
      else setActiveIndex((prevIndex) => prevIndex + 1);

    } else if (keyCode === 38) {
      // move up
      if (activeIndex === 0) setActiveIndex(searchResult.length - 1);
      else setActiveIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <form className="relative flex flex-1" action="#" method="GET" onSubmit={(e) => e.preventDefault()}>
        <label htmlFor="search-field" className="sr-only">
            Search
        </label>
        <MagnifyingGlassIcon className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-fontDark" aria-hidden="true" />
        <input
            id="search-field"
            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-fontDark text-[16px] leading-24 placeholder:text-fontGrey1 focus:ring-0 sm:text-sm"
            placeholder="Type to search for jobs titles and job ids"
            name="search"
            value={searchQuery}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            autoComplete='off'
            />
    </form>
  )
}

export default SearchBarInput