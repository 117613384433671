import { ComponentType, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { classNames } from 'utils/string'
import { EllipsisHorizontalIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { IThreeDotMenuProps } from './threeDotMenu.types'

const ThreeDotMenu: ComponentType<IThreeDotMenuProps> = ({variant = "horizontal", options,data }) => {

  const menuIcon = (
    variant == "horizontal" ?
    <EllipsisHorizontalIcon className='h-6 w-6 text-[#0F172A]' /> : <EllipsisVerticalIcon className='h-6 w-6 text-[#0F172A]' />)

    return (
    <Menu as="div" className="relative inline-block text-left" style={{ overflow: "visible" }}>
      <div>
        <Menu.Button className="inline-flex w-fit justify-center gap-x-1.5 rounded-md bg-white hover:bg-gray-50">
          {menuIcon}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        >
        <Menu.Items className="absolute right-0 z-[60] w-fit origin-top-right rounded-md bg-[#FFFFFF] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
                <Menu.Item>
                    {({ active }) => (
                        <button
                            className={classNames(
                                active ? 'bg-borderSecondary' : '',
                                'px-4 py-2 text-sm  whitespace-nowrap flex items-center w-full focus:border-y-[1px] active:border-primary'
                                )}
                            onClick={() => option.handleClick?.(data)}
                        >
                            <>{option.icon}</>
                            <p className='text-[16px] leading-5 text-[#7A7A7A] pl-1'>{option.optionText}</p>
                        </button>
                    )}
                </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default ThreeDotMenu