export enum WebApiEndPointUrls {
  GET_TEMP_PERSON_DATA = "https://jsonplaceholder.typicode.com",
  PASSWORDLESS_AUTH = "/auth/passwordless-auth",
  OAUTH_AUTH = "/auth/oauth",
  USER_ACCOUNT = "/user",
  LOGOUT_ACCOUNT = "/auth/logout",
  PERMISSIONS = "/manage/permissions",
  ROLES = "/roles",
  TEAM = "/manage/team",
  MEMBER = "/manage/member",
  EDIT_MEMBER = "/manage/user"
}
