import { GET_TEMP_PERSON_DATA, GET_TEMP_PERSON_DATA_FAILIURE, GET_TEMP_PERSON_DATA_SUCCESS } from "../../constant/global-const/action-type.const";
import { IGetPersonDataActionPayload, IGetPersonDataFailure, IGetPersonDataSuccess } from "../../interfaces/temp-flow.model";
import { IGetTempPersonData, IGetTempPersonFailureData, IGetTempPersonSuccessData } from "../types/temp-flow.type";

export const getTempPersonData = (payload: IGetPersonDataActionPayload): IGetTempPersonData => ({
  type: GET_TEMP_PERSON_DATA,
  payload
});
export const getTempPersonDataSuccess = (payload: IGetPersonDataSuccess): IGetTempPersonSuccessData => ({
  type: GET_TEMP_PERSON_DATA_SUCCESS,
  payload
});
export const getTempPersonDataFailiure = (payload: IGetPersonDataFailure): IGetTempPersonFailureData => ({
  type: GET_TEMP_PERSON_DATA_FAILIURE,
  payload
});
