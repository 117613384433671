import React from 'react'
import { classNames } from 'utils/string'

const Item = ({
  title,
  icon: Icon,
  selectedIcon: SelectedIcon,
  isHovered,
  setIsHovered
}) => {
  return (
    <li key={title} onMouseEnter={() => setIsHovered(title)}
      onMouseLeave={() => setIsHovered("")}>
      <div 
        className={classNames(
          title === isHovered
            ? 'bg-gray-50 '
            : 'text-gray-700  hover:bg-gray-50',
          'cursor-pointer group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700'
        )}
      >
        {title === isHovered ? (
          <SelectedIcon
            className={`h-6 w-6 shrink-0 text-fontDark `}
            aria-hidden="true"
          />
        ) : (
          <Icon
            className={`h-6 w-6 shrink-0 ${title === isHovered ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600'
              } `}
            aria-hidden="true"
          />
        )}
        {title}
      </div>
    </li>
  )
}

export default Item
