import { Button } from "atoms";
import { useParams } from "react-router";

const TermsConditions = () => {
  const params = useParams();

  const acceptTerms = () => {
    if (params && params.inviteToken) {
      window.location.href = process.env.REACT_APP_BASE_API_ENDPOINT + "/auth/invite/" + params.inviteToken + "/accept";
    }
  };

  return (
    <div className="relative">
      <div className="bg-[#121212] w-full mx-auto text-[#ffffff] font-medium py-20 text-center text-4xl md:text-6xl font-sans">
        Terms and Conditions
      </div>
      <div className="container mx-auto px-8 lg:px-40 py-8">
        {/* Agreement */}
        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Agreement</h2>
          <p className="mb-4 text-[#4A4C52]">
            We may collect personal information such as your name, email address, contact information, and other data when you interact with our
            website or services. This information is collected for the purpose of providing and improving our services, responding to your inquiries,
            and enhancing your user experience.
          </p>
          <p className="mb-4 text-[#4A4C52]">
            Date the account is created by the user, by and between Jobphin Inc., a global feedback management and personalization service company
            (hereinafter referred to as "the Company"), and its clients (hereinafter referred to as "the Clients"). This Agreement outlines the terms
            and conditions governing the provision and use of the Company's services, ensuring transparency, fairness, and exceptional client
            experience.
          </p>
        </div>

        {/* Definitions */}
        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Definitions</h2>
          <ol className="text-[#4A4C52]">
            <li className="mb-2">
              1. "Jobphin Inc." refers to the global feedback management and personalization service company that operates globally, providing a
              unique service of tracking applicant data and providing direct feedback to candidates on behalf of employers.
            </li>
            <li className="mb-2">
              2. Global feedback management and personalization service refers to a software application that enables the electronic handling of
              recruitment and hiring needs related to sending candidates personalized feedback.
            </li>
            <li className="mb-2">
              3. "Clients" refer to businesses that utilize Jobphin Inc.'s services, seeking an efficient ATS that not only tracks applicant data but
              also enhances the candidate experience by providing direct feedback.
            </li>
            <li className="mb-2">
              4. "Services" refer to the scope of services provided by Jobphin Inc., including editing and sending feedback to candidates, integrating
              with the client's ATS to receive job, applicant, and application level details, and sending unlimited feedback until the fair use policy
              limit is reached.
            </li>
            <li className="mb-2">
              5. "Subscription Model" refers to the monetization structure of Jobphin Inc., charging a monthly fee per recruiter seat.
            </li>
            <li className="mb-2">
              6. "Fair Use Policy" refers to the policy that sets the limit on the usage of Jobphin Inc.'s services. If the limit is exceeded, Jobphin
              Inc. reserves the right to request lower usage or discuss an upgraded paid plan.
            </li>
            <li className="mb-2">
              7. "Legal Risks" refer to potential legal issues associated with perceived discrimination due to the unique nature of Jobphin Inc.'s
              services.
            </li>
          </ol>
        </div>

        {/* Payment Methods */}
        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Payment Methods</h2>
          <ol className="text-[#4A4C52]">
            <li className="mb-2">
              1. Acceptable Forms of Payment: Jobphin Inc. accepts payments for subscription fees through various forms including, but not limited to,
              credit cards, debit cards, bank transfers, and other recognized electronic payment methods.
            </li>
            <li className="mb-2">
              2. Billing Cycle: Payments for the services rendered by Jobphin Inc. are due on a monthly basis. The billing cycle commences from the
              date of subscription and continues on the same date of each subsequent month.
            </li>
            <li className="mb-2">
              3. Pricing Structure: The subscription fee for the services provided by Jobphin Inc. is $49.99 per recruiter seat per month. This fee is
              exclusive of any applicable taxes or additional fees, which will be clearly stated in the invoice.
            </li>
            <li className="mb-2">
              4. Free Trial Period: Jobphin Inc. offers a free trial period for the first three months from the date of subscription. Unless the
              client cancels before the end of the trial period, the subscription will automatically transition to a paid subscription at the regular
              rate.
            </li>
            <li className="mb-2">
              5. Non-Payment and Late Payment: In the event of non-payment or late payment, Jobphin Inc. reserves the right to suspend the services,
              terminate the contract, or impose late fees. A grace period of 15 days will be provided for clients to rectify payment issues.
            </li>
            <li className="mb-2">
              6. Chargebacks and Disputes: If a client disputes a charge, they must notify Jobphin Inc. in writing within 30 days of the charge.
              Jobphin Inc. will investigate the dispute and provide a resolution in accordance with applicable laws and regulations.
            </li>
            <li className="mb-2">
              7. Service Plan Changes: Clients may upgrade or downgrade their service plans at any time. Any additional charges or refunds resulting
              from such changes will be prorated and reflected in the next billing cycle.
            </li>
            <li className="mb-2">
              8. Subscription Cancellation: Clients may cancel their subscription at any time by providing written notice to Jobphin Inc.
              Cancellations will take effect at the end of the current billing cycle, and no refunds will be provided for partial months.
            </li>
            <li className="mb-2">
              9. Compliance with Laws: This Payment Methods clause is compliant with the laws and regulations of California and New York, where
              Jobphin Inc. has a significant presence.
            </li>
            <li className="mb-2">
              10. Modification of Payment Terms: Jobphin Inc. reserves the right to modify the payment terms at any time. Clients will be notified of
              any changes in advance and will have the option to continue or terminate their subscription based on the new terms.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Limitation Of Liability</h2>
          <ol className="text-[#4A4C52]">
            <li className="mb-2">
              1. Scope: This Limitation of Liability clause applies to all services provided by Jobphin Inc., including but not limited to the
              tracking of applicant data and the provision of feedback to candidates.
            </li>
            <li className="mb-2">
              2. Limitation Amount: Jobphin Inc.'s liability to the client for any claim arising out of or related to the services provided under this
              agreement shall be limited to the amount of fees paid by the client to Jobphin Inc. within the twelve (12) month period preceding the
              claim.
            </li>
            <li className="mb-2">
              3. Types of Damages: This limitation of liability includes, but is not limited to, direct damages and excludes indirect, incidental,
              consequential, special, or exemplary damages, including but not limited to, lost profits or loss of business.
            </li>
            <li className="mb-2">
              4. Form of Action: This limitation of liability applies regardless of the form of action, whether in contract, tort (including
              negligence), strict liability, or otherwise, even if Jobphin Inc. has been advised of the possibility of such damages.
            </li>
            <li className="mb-2">
              5. Services Provided "As Is": The services are provided "as is" and Jobphin Inc. disclaims all warranties, express or implied, to the
              extent permitted by law.
            </li>
            <li className="mb-2">
              6. Fundamental Element: This limitation of liability is a fundamental element of the basis of the bargain between Jobphin Inc. and the
              client, and the services would not be provided without such limitations.
            </li>
            <li className="mb-2">
              7. Jurisdictional Limitations: Certain states or jurisdictions do not allow the exclusion or limitation of liability for consequential
              or incidental damages, so the above limitations may not apply to every client. In such cases, Jobphin Inc.'s liability will be limited
              to the greatest extent permitted by law.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Governing Law</h2>
          <ol className="text-[#4A4C52]">
            <li className="mb-2">
              1. Governing Law and Jurisdiction: These Terms and Conditions, and any disputes arising out of or related to these Terms and Conditions
              or the services provided by Jobphin Inc., shall be governed by and construed in accordance with the laws of the State of California,
              United States, without regard to its conflict of laws principles.
            </li>
            <li className="mb-2">
              2. Exclusive Jurisdiction: The state and federal courts located within the State of California, United States, shall have exclusive
              jurisdiction over any and all disputes arising from or related to these Terms and Conditions or the services provided by Jobphin Inc. By
              using these services, you consent to the personal jurisdiction of such courts and waive any claim of inconvenient forum.
            </li>
            <li className="mb-2">
              3. Application of Law: The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly
              excluded.
            </li>
            <li className="mb-2">
              4. Compliance with Laws: You agree to comply with all local, state, federal, and national laws, statutes, ordinances, and regulations
              that apply to your use of our services.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Acceptable uses</h2>
          <ol className="text-[#4A4C52]">
            <li className="mb-2">
              1. Scope of Use: The services provided by Jobphin Inc. are intended solely for legitimate human resources and recruitment purposes. Any
              other use is strictly prohibited unless explicitly authorized by Jobphin Inc. in writing.
            </li>
            <li className="mb-2">
              2. Prohibited Conduct: Users are strictly prohibited from engaging in any activity that could be considered discriminatory, harassing,
              or in violation of any employment laws or regulations. This includes, but is not limited to, the use of the service to discriminate
              against candidates based on race, color, religion, sex, national origin, age, disability, or any other protected class.
            </li>
            <li className="mb-2">
              3. Feedback Usage: The feedback provided to candidates is for personal development purposes only. It should not be shared or used for
              any other purpose without explicit written permission from Jobphin Inc.
            </li>
            <li className="mb-2">
              4. Data Integrity: Users are prohibited from manipulating or falsifying applicant data within the system. Any attempt to alter, delete,
              or fabricate data is strictly prohibited and may result in immediate termination of the user's account and legal action.
            </li>
            <li className="mb-2">
              5. Fair Use Policy: Users are required to adhere to the fair use policy, which limits the number of feedback messages that can be sent.
              Exceeding these limits may result in additional charges or service restrictions.
            </li>
            <li className="mb-2">
              6. System Security: Users are prohibited from engaging in any activities that could compromise the security of the system, such as
              attempting to gain unauthorized access or introducing malicious software.
            </li>
            <li className="mb-2">
              7. Intellectual Property: Users are strictly prohibited from reverse engineering, decompiling, or disassembling the software. Any such
              actions will be considered a violation of Jobphin Inc.'s intellectual property rights.
            </li>
            <li className="mb-2">
              8. Service Interference: Any use of the service in a manner that could damage, disable, overburden, or impair the service or interfere
              with any other party's use is strictly prohibited.
            </li>
            <li className="mb-2">
              9. Breach of Clause: Any breach of this Acceptable Use clause may result in immediate termination of the user's account and legal action
              if necessary.
            </li>
            <li className="mb-2">
              10. Accessibility: This clause is easily accessible and understandable to users. It outlines the rules and restrictions regarding the
              proper use of the services provided by Jobphin Inc.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">User-Generated Content</h2>
          <ol className="text-[#4A4C52]">
            <li className="mb-2">
              1. Definition: For the purposes of this clause, "User-Generated Content" (UGC) shall refer to all forms of content, including but not
              limited to feedback, interview responses, and any other materials, created or submitted by users through the services provided by
              Jobphin Inc.
            </li>
            <li className="mb-2">
              2. License Grant: By submitting UGC, users hereby grant Jobphin Inc. a perpetual, irrevocable, worldwide, royalty-free, and
              non-exclusive license to use, reproduce, modify, publish, translate, create derivative works from, distribute, perform, and display such
              content in any media.
            </li>
            <li className="mb-2">
              3. Ownership: Jobphin Inc. does not claim ownership over the content originally provided by users. However, Jobphin Inc. retains all
              rights to the feedback and data generated from the use of its services.
            </li>
            <li className="mb-2">
              4. User Representations: Users represent and warrant that they own or have the necessary licenses, rights, consents, and permissions to
              submit the UGC and that the UGC does not infringe upon any third-party rights, including intellectual property rights and privacy
              rights.
            </li>
            <li className="mb-2">
              5. Content Removal: Jobphin Inc. reserves the right, at its sole discretion, to remove or refuse any UGC that it considers to be in
              violation of these Terms and Conditions, or that is otherwise objectionable or inappropriate, without prior notice.
            </li>
            <li className="mb-2">
              6. Indemnification: Users agree to indemnify and hold harmless Jobphin Inc. from any claim or demand made by any third party due to or
              arising out of the UGC they submit, post, transmit, or otherwise make available through the service.
            </li>
            <li className="mb-2">
              7. Relationship: The submission of UGC does not create any confidential, fiduciary, or any other special relationship between the user
              and Jobphin Inc.
            </li>
            <li className="mb-2">
              8. Monitoring: Jobphin Inc. may, but has no obligation to, monitor the UGC submitted by users to ensure compliance with these Terms and
              Conditions.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Intellectual Property</h2>
          <ol className="text-[#4A4C52]">
            <li className="mb-2">
              1. Scope of Intellectual Property: The Intellectual Property (IP) of Jobphin Inc. encompasses all materials and elements related to the
              services provided by the company. This includes, but is not limited to, software, algorithms, databases, user interfaces, reports,
              feedback content, trademarks, service marks, logos, and any related documentation.
            </li>
            <li className="mb-2">
              2. Ownership and Protection: All IP as defined above is the exclusive property of Jobphin Inc. and is protected under the applicable
              U.S. and international intellectual property laws.
            </li>
            <li className="mb-2">
              3. Limited License: The use of Jobphin Inc.'s services does not confer any IP rights to the user. Users are granted a limited,
              non-exclusive, non-transferable license to use the services as intended under the Terms and Conditions.
            </li>
            <li className="mb-2">
              4. User-Generated Content: Any user-generated content provided to Jobphin Inc. grants the company a perpetual, irrevocable, royalty-free
              license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content
              globally in any media.
            </li>
            <li className="mb-2">
              5. Third-Party Intellectual Property: Users must ensure they have the right to use any third-party IP when using Jobphin Inc.'s
              services. Users agree to indemnify Jobphin Inc. against any claims of infringement related to such third-party IP.
            </li>
            <li className="mb-2">
              6. Prohibition of Reverse Engineering: Users are prohibited from reverse engineering, decompiling, or disassembling Jobphin Inc.'s
              software or services, in order to protect the company's proprietary technology.
            </li>
            <li className="mb-2">
              7. Feedback and Suggestions: Any feedback, comments, or suggestions users may provide regarding Jobphin Inc.'s services becomes the sole
              property of the company. Jobphin Inc. reserves the right to use and incorporate such feedback without any obligation to the user.
            </li>
            <li className="mb-2">
              8. Legal Action: Jobphin Inc. reserves the right to take legal action against any IP infringement that threatens the company's rights or
              the integrity of its services.
            </li>
            <li className="mb-2">
              9. Updates and Changes: This clause will be updated as necessary to reflect changes in IP law or industry practices, and will always be
              consistent with the legal requirements of California and New York, where Jobphin Inc. primarily operates.
            </li>
          </ol>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Severability#</h2>
          <p className="text-[#4A4C52]">
            In the event that any provision of this Agreement is found to be invalid or unenforceable under any applicable law, such invalidity or
            unenforceability will not render the entire Agreement invalid or unenforceable. Instead, the invalid or unenforceable provision will be
            deemed severed from this Agreement and the remaining provisions will continue in full force and effect.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Waiver#</h2>
          <p className="text-[#4A4C52]">
            No failure or delay by either party in exercising any right, power, or privilege under this Agreement will operate as a waiver thereof,
            nor will any single or partial exercise there of preclude any other or further exercise thereof or the exercise of any other right, power,
            or privilege.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-medium mb-4">Entire Agreement# </h2>
          <p className="text-[#4A4C52]">
            This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior
            discussions, negotiations, and agreements, whether oral or written. This Agreement may only be amended in writing and signed by both
            parties.
          </p>
        </div>

        <div className="mb-8">
          <p className="text-[#4A4C52]">
            The account creator hereby acknowledges that they have read and understood the terms and conditions of this Agreement, dated as of the
            date the account is created by the user, made between Jobphin Inc. (hereinafter referred to as "the Company") and the client (hereinafter
            referred to as "the Client"). The Client affirms that the information provided in this Agreement is true and accurate to the best of their
            knowledge. The Company confirms its commitment to providing the specified services as outlined in this Agreement.
          </p>
        </div>
      </div>
      {params && params.inviteToken && (
        <div className="fixed left-1/2 bottom-8 -translate-x-2/4 shadow-lg" id="accept-btn">
          <Button onClick={acceptTerms}>Accept and Continue</Button>
        </div>
      )}
    </div>
  );
};

export default TermsConditions;
